import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { countryOptions } from "../../shared/selectOptions";
import { useNavigate } from "react-router-dom";
import { State, Country } from "country-state-list";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";
import { useMsal } from "@azure/msal-react";

function ShippingDetails({
  userData,
  isSaveDisabled,
  setIsSaveDisabled,
  loggedUser,
}) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [isLoading, setIsLoading] = useState(false);
  const [shippingStateDisabled, setShippingStateDisabled] = useState(true);
  const [billingStateDisabled, setBillingStateDisabled] = useState(true);
  const [showBillingAddress, setShowBillingAddress] = useState(false);
  const [textBillingButton, setTextBillingButton] = useState("");
  const [activeAccount, setActiveAccount] = useState({});
  const [countries, setCountries] = useState([]);
  const [shippingStateList, setShippingStateList] = useState([]);
  const [billingStateList, setBillingStateList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const onHandleIsSameAddressCheck = (e) => {
    setIsSaveDisabled(false);
    if (e.target.checked) {
      const addressValues = form.getFieldsValue();
      if (addressValues.shippingCountry.toLowerCase() === "united states") {
        var states = State.getStatesOfCountry("US");
        setBillingStateList(states);
        setBillingStateDisabled(false);
      } else if (addressValues.shippingCountry.toLowerCase() === "canada") {
        var states = State.getStatesOfCountry("CA");
        setBillingStateList(states);
        setBillingStateDisabled(false);
      }

      //Copy Values to Billing Address
      form.setFieldsValue({
        billingCountry: addressValues.shippingCountry,
        billingAddress1: addressValues.shippingAddress1,
        billingAddress2: addressValues.shippingAddress2,
        billingCity: addressValues.shippingCity,
        billingState: addressValues.shippingState,
        billingZipCode: addressValues.shippingZipCode,
      });
    } else {
      //Reset values from Billing Address
      form.setFieldsValue({
        billingCountry: null,
        billingAddress1: null,
        billingAddress2: null,
        billingCity: null,
        billingState: null,
        billingZipCode: null,
      });
      setBillingStateList([]);
      setBillingStateDisabled(true);
    }
  };

  const removeBillingAddress = () => {
    const addressValues = form.getFieldsValue();
    if (addressValues.billingCountry || addressValues.billingAddress1 || addressValues.billingAddress2 ||
      addressValues.billingCity || addressValues.billingState || addressValues.billingZipCode) {
      setIsSaveDisabled(false);
    }
    form.setFieldsValue({
      billingCountry: null,
      billingAddress1: null,
      billingAddress2: null,
      billingCity: null,
      billingState: null,
      billingZipCode: null,
      // isMyBillingAddress: false,
    });
    setBillingStateList([]);
    setBillingStateDisabled(true);
  }

  const onShippingCountryChange = (value) => {
    setIsSaveDisabled(false);
    form.setFieldsValue({ shippingState: null });
    if (value.toLowerCase() === "united states") {
      setShippingStateDisabled(false)
      let states = State.getStatesOfCountry("US");
      setShippingStateList(states);
    } else if (value.toLowerCase() === "canada") {
      setShippingStateDisabled(false)
      let states = State.getStatesOfCountry("CA");
      setShippingStateList(states);
      } else {
      setShippingStateDisabled(true)
      setBillingStateList([]);
    }
  };

  const onBillingCountryChange = (value) => {
    setIsSaveDisabled(false);
    form.setFieldsValue({ billingState: null });
    if (value.toLowerCase() === "united states") {
      setBillingStateDisabled(false)
      let states = State.getStatesOfCountry("US");
      setBillingStateList(states);
    } else if (value.toLowerCase() === "canada") {
      setBillingStateDisabled(false)
      let states = State.getStatesOfCountry("CA");
      setBillingStateList(states);
      } else {
      setBillingStateDisabled(true)
      setBillingStateList([]);
    }
  };


  const onShippingStateChange = (value) => {
    setIsSaveDisabled(false);
  };

  const onShippingStateSearch = (value) => {
    // console.log(value);
  };
  
  const shippingStateFilter = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  }

  const onBillingStateChange = (value) => {
    setIsSaveDisabled(false);
  };

  const onBillingStateSearch = (value) => {
    // console.log(value);
  };

  const billingStateFilter = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleFormChange = () => {
    setIsSaveDisabled(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    //Call Service to update user data
    //CREATE USER OBJECT
    const updatedUser = {
      email: userData.email,
      shippingCountry: values.shippingCountry,
      shippingAddress1: values.shippingAddress1,
      shippingAddress2: values.shippingAddress2,
      shippingCity: values.shippingCity,
      shippingState: values.shippingState ?? null,
      shippingZipCode: values.shippingZipCode,
      isMyBillingAddress: values.isMyBillingAddress,
      billingCountry: values.billingCountry ?? null,
      billingAddress1: values.billingAddress1 ?? null,
      billingAddress2: values.billingAddress2 ?? null,
      billingCity: values.billingCity ?? null,
      billingState: values.billingState ?? null,
      billingZipCode: values.billingZipCode ?? null,
    };
    const _fetchClient = new FetchClient(activeAccount.idToken);
    //CREATE USER SERVICE INSTANCE
    const _userService = new UserService(_fetchClient);

    try {
      //CALL USER SERVICE API
      let res = await _userService.updateUser(updatedUser);
      navigate(".", { state: { userData: res } }); 

      //SHOW SUCCESS NOTIFICATION MESSAGE
      messageApi.open({
        type: "success",
        content: "User profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      setIsSaveDisabled(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  };

  useEffect(() => {
    if (instance) {
      setActiveAccount(instance.getActiveAccount())
    }
    if (countries != []) {
      setCountries(Country.getAllCountries().map(country => {
        return {
          label: country.name,
          value: country.name
        }
      }))
    }
    //SET SHIPPING STATE DROPDOWN
    if (userData.shippingCountry?.toLowerCase() === "united states") {
      let states = State.getStatesOfCountry("US");
      setShippingStateList(states);
      setShippingStateDisabled(false);
    } else if (userData.shippingCountry?.toLowerCase() === "canada") {
      let states = State.getStatesOfCountry("CA");
      setShippingStateList(states);
      setShippingStateDisabled(false);
    } else {
      setShippingStateList([]);
      setShippingStateDisabled(true);
    }

    //SET BILLING STATE DROPDOWN
    if (userData.billingCountry?.toLowerCase() === "united states") {
      let states = State.getStatesOfCountry("US");
      setBillingStateList(states);
      setBillingStateDisabled(false);
    } else if (userData.billingCountry?.toLowerCase() === "canada") {
      let states = State.getStatesOfCountry("CA");
      setBillingStateList(states);
      setBillingStateDisabled(false);
    } else {
      setBillingStateList([]);
      setBillingStateDisabled(true);
    }

    if (userData.billingCountry || userData.billingAddress1 || userData.billingAddress2 ||
      userData.billingCity || userData.billingState || userData.billingZipCode) {
      setTextBillingButton("Remove Billing Address")
      setShowBillingAddress(true);
    } else {
      setTextBillingButton("Add Billing Address")
    }
  }, []);

  //CONFIRM MODAL
  const showConfirm = () => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you would like to remove the Billing Address?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        removeBillingAddress();
        setShowBillingAddress(!showBillingAddress)
        setTextBillingButton("Add Billing Address");
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Space
        size="small"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Button
          // onClick={}
          onClick={() => {
            if (showBillingAddress)
              showConfirm()
            else {
              setShowBillingAddress(!showBillingAddress)
              setTextBillingButton("Remove Billing Address");
            }

          }}
          className="confirmUserActionsButton"
          // style={{ marginBottom: "8px" }}
        >
          {textBillingButton}
        </Button>
      </Space>
      <Form
        size="large"
        form={form}
        name="addressesForm"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          shippingCountry: userData.shippingCountry,
          shippingAddress1: userData.shippingAddress1,
          shippingAddress2: userData.shippingAddress2,
          shippingCity: userData.shippingCity,
          shippingState: userData.shippingState,
          shippingZipCode: userData.shippingZipCode,
          billingCountry: userData.billingCountry,
          billingAddress1: userData.billingAddress1,
          billingAddress2: userData.billingAddress2,
          billingCity: userData.billingCity,
          billingState: userData.billingState,
          billingZipCode: userData.billingZipCode,
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <span className="addressProfileTitle">Shipping Address</span>
          </Col>
          {showBillingAddress &&
          <>
            <Col span={5}>
              <span className="addressProfileTitle">Billing Address</span>
            </Col>
            <Col span={7} style={{ textAlign: "left" }}>
              <Form.Item style={{ margin: 0 }} name="isMyBillingAddress" valuePropName="checked">
                <Checkbox onChange={onHandleIsSameAddressCheck}>
                  Same as shipping address
                </Checkbox>
              </Form.Item>
            </Col>
          </>
          }
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item 
              label="Country" 
              name="shippingCountry"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                },
              ]}
            >
              <Select
                label="Country"
                showSearch
                options={countries}
                onChange={onShippingCountryChange}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {showBillingAddress && (
              <Form.Item 
                label="Country" 
                name="billingCountry"
                rules={[
                  {
                    required: showBillingAddress,
                    message: "This field is required"
                  },
                ]}
              >
                <Select
                  label="Country"
                  showSearch
                  options={countries}
                  onChange={onBillingCountryChange}
                ></Select>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item 
              label="Address 1" 
              name="shippingAddress1"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                },
              ]}
            >
              <Input
                name="shippingAddress1"
                onChange={handleFormChange}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            {showBillingAddress && (
              <Form.Item 
                label="Address 1" 
                name="billingAddress1"
                rules={[
                  {
                    required: showBillingAddress,
                    message: "This field is required"
                  },
                ]}
              >
                <Input name="billingAddress1" onChange={handleFormChange}></Input>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Address 2" name="shippingAddress2">
              <Input
                name="shippingAddress2"
                onChange={handleFormChange}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            {showBillingAddress && (
              <Form.Item label="Address 2" name="billingAddress2">
                <Input name="billingAddress2" onChange={handleFormChange}></Input>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item 
              label="City" 
              name="shippingCity"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                },
              ]}
            >
              <Input name="shippingCity" onChange={handleFormChange}></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            {showBillingAddress && (
              <Form.Item 
                label="City" 
                name="billingCity"
                rules={[
                  {
                    required: showBillingAddress,
                    message: "This field is required"
                  },
                ]}
              >
                <Input name="billingCity" onChange={handleFormChange}></Input>
              </Form.Item>
            )}
          </Col>
        </Row>
        {(!shippingStateDisabled || !billingStateDisabled) &&
          <Row gutter={24}>
            <Col span={12}>
              {!shippingStateDisabled && 
                <Form.Item 
                  label="State" 
                  name="shippingState"
                  rules={[
                    {
                      required: !shippingStateDisabled,
                      message: "This field is required"
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                    onChange={onShippingStateChange}
                    onSearch={onShippingStateSearch}
                    filterOption={shippingStateFilter}
                    options={(shippingStateList || []).map((s) => ({
                      value: s.name,
                      isoCode: s.isoCode,
                      label: s.name + " (" + s.isoCode + ")",
                    }))}
                  />
                </Form.Item>
              }
            </Col>
            <Col span={12}>
              {!billingStateDisabled && showBillingAddress &&
                <Form.Item 
                  label="State" 
                  name="billingState"
                  rules={[
                    {
                      required: showBillingAddress && !billingStateDisabled,
                      message: "This field is required"
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                    onChange={onBillingStateChange}
                    onSearch={onBillingStateSearch}
                    filterOption={billingStateFilter}
                    options={(billingStateList || []).map((s) => ({
                      value: s.name,
                      isoCode: s.isoCode,
                      label: s.name + " (" + s.isoCode + ")",
                    }))}
                  />
                </Form.Item>
              }
            </Col>
          </Row>
        }
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item 
              label="ZIP Code" 
              name="shippingZipCode"
              rules={[
                {
                  required: !shippingStateDisabled,
                  message: "This field is required"
                },
              ]}
            >
              <Input name="shippingZipCode" onChange={handleFormChange}></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            {showBillingAddress && (
              <Form.Item
                label="ZIP Code"
                name="billingZipCode"
                rules={[
                  {
                    required: showBillingAddress && !billingStateDisabled,
                    message: "This field is required"
                  },
                ]}
              >
                <Input name="billingZipCode" onChange={handleFormChange}></Input>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Form.Item>
              <Button
                size="middle"
                htmlType="submit"
                className="confirmButton"
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ShippingDetails;
