import React from "react";
import {
  Card,
  Flex
} from "antd";

function ClubMembership() {
  return (
    <Card>
      <Flex>
        <Flex>
          <div className="centered text-4xl h-screen">
            <span style={{ fontSize: 16 }}>
              Coming soon...
            </span>
          </div>
        </Flex>
      </Flex>
    </Card>
  );
}
export default ClubMembership;
