const config = require("../config");

class NoteService {
  constructor(httpClient) {
    this.BASE_URL = config.BASE_URL;
    this.httpClient = httpClient;
  }

  async getAllNotes(eventId) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/GetAllNotes?",
        eventId
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch notes.");
      }

      const notes = await response.json();
      return notes;
    } catch (error) {
      console.error("Error fetching notes: ", error);
      if (error.status === 401) {
        throw error;
      } else {
        return [];
      }
    }
  }

  async createNote(note) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/CreateNote",
        note
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async batchUpdateNotes(notesActionModel) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/BatchNoteUpdate",
        notesActionModel
      );

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateNote(note) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/UpdateNote",
        note
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async deleteNote(note) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/DeleteNoteById",
        note
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }
  
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default NoteService;
