import React from "react";
import Form from "antd/es/form/Form";
import { Button, Space } from "antd";
import { SubmitButton } from "./SubmitButton";
export const ModalFooter = ({form, onCancel, onConfirm, submitText}) => {
  return (
    <Form.Item>
      <Space style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          key="cancel"
          type="primary"
          style={{ background: "#26225A", borderColor: "white" }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <SubmitButton form={form} onClickAction={onConfirm}>
          {submitText}
        </SubmitButton>
      </Space>
    </Form.Item>
  )
}
