import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { teacherStatusOptions } from "../../shared/selectOptions";
import dayjs from "dayjs";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";

function AccountDetails({
  userData,
  isSaveDisabled,
  setIsSaveDisabled,
  isSameAdminUser
}) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailAddressEditable, setIsEmailAddressEditable] = useState(true);
  const [membershipLevelEnabled, setMembershipLevelEnabled] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [passwordEnabled, setPasswordEnabled] = useState(false);
  const [email, setEmail] = useState(userData?.email ? userData.email : null);
  const [prospectDate, setProspectDate] = useState(null);
  const [beamerDate, setBeamerDate] = useState(null);
  const [pathSeekerDate, setPathSeekerDate] = useState(null);
  const [teacherDate, setTeacherDate] = useState(null);

  let loggedUser;
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    if (instance) {
      loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      if (!loggedUser) {
        getLoggedUser().then((res) => {
          loggedUser = res;
        });
      }
      
      let isPasswordEnabled = false;
      //CHECK USER ROLE PERMISSIONS
      if (isSameAdminUser) {
        setIsEmailAddressEditable(true);
        setMembershipLevelEnabled(false);
        isPasswordEnabled = true;
      } else if (loggedUser.role.toLowerCase() === "admin") {
        setIsEmailAddressEditable(true);
        setMembershipLevelEnabled(true);
        isPasswordEnabled = false;
      } else if (loggedUser.role.toLowerCase() === "user") {
        setIsEmailAddressEditable(true);
        setMembershipLevelEnabled(false);
        isPasswordEnabled = true;
      } else if (loggedUser.role.toLowerCase() === "teachingcoordinator") {
        setIsEmailAddressEditable(true);
        setMembershipLevelEnabled(false);
        isPasswordEnabled = true;
      } else if (loggedUser.role.toLowerCase() === "clubadmin") {
        setIsEmailAddressEditable(true);
        setMembershipLevelEnabled(false);
        isPasswordEnabled = true;
      } else {
        setIsEmailAddressEditable(false);
        setMembershipLevelEnabled(false);
        isPasswordEnabled = false;
      }

      if (activeAccount != null && (userData?.email === activeAccount?.username)) {
        isPasswordEnabled = true;
      }
      setPasswordEnabled(isPasswordEnabled);
    }
  }, [instance, loggedUser, passwordEnabled, userData.email]);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      form.setFieldsValue({
        email: userData.email
      });
      setProspectDate(userData.prospectDate ? dayjs(userData.prospectDate) : null);
      setBeamerDate(userData.beamerDate ? dayjs(userData.beamerDate) : null);
      setPathSeekerDate(userData.pathSeekerDate ? dayjs(userData.pathSeekerDate) : null);
      setTeacherDate(userData.teacherDate ? dayjs(userData.teacherDate) : null);
      setEmail(userData.email);
    }
  }, [userData]);

  const getLoggedUser = async () => {
    try {
      const _fetchInstance = new FetchClient(activeAccount.idToken);
      const _userService = new UserService(_fetchInstance);
      var logUser = await _userService.getUserByEmail(
        activeAccount.idTokenClaims.emails[0]
      );
      if (logUser) {
        localStorage.setItem("loggedUser", JSON.stringify(logUser));
        return logUser;
      }
      return null;
    } catch (error) {
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  }

  const onChangeProspectDate = (date, dateString) => {
    setProspectDate(dateString ? dayjs(dateString) : null);
    setIsSaveDisabled(false);
  };
  const onChangeBeamerDate = (date, dateString) => {
    setBeamerDate(dateString ? dayjs(dateString) : null);
    setIsSaveDisabled(false);
  };

  const onChangePathSeekerDate = (date, dateString) => {
    setPathSeekerDate(dateString ? dayjs(dateString) : null);
    setIsSaveDisabled(false);
  };

  const onChangeTeacherDate = (date, dateString) => {
    if (dateString) {
      const userValues = form.getFieldsValue();
      setTeacherDate(dayjs(dateString));
      form.setFieldsValue({ teachingStatus: userValues.teachingStatus ? userValues.teachingStatus : "Active" });
    }
    else {
      setTeacherDate(null);
      form.setFieldsValue({ teachingStatus: null });
    }
    setIsSaveDisabled(false);
  };

  const handleFormChange = () => {
    setIsSaveDisabled(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    //Call Service to update user data
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _userService = new UserService(_fetchClient);

    if (values.currentPassword && values.newPassword && values.confirmNewPassword) {
      const passwordModel = {
        email: values.email,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      };
      let messageType, messageContent;
      try {
        let res = await _userService.updatePassword(passwordModel);
        messageType = res.status == 200 ? "success" : "error";
        messageContent = res.status == 200 ?  "User profile updated successfully!" : res.status == 401 ? "Invalid password format for new password" : "Incorrect current password";
        messageApi.open({
          type: messageType,
          content: messageContent,
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });
        setIsLoading(false);
        setIsSaveDisabled(true);
        form.setFieldsValue({
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        });
      } catch (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: "Something went wrong. Please try again later.",
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });

        setIsLoading(false);
        setIsSaveDisabled(true);
        form.setFieldsValue({
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        });

        if (error.code === 401) {
          localStorage.removeItem("loggedUser");
          await instance.logout({
            onRedirectNavigate: () => {
              return false;
            }
          });
          await instance.loginRedirect();
        }
      }
    }
    else {
      const updatedUser = {
        email: values.email,
        prospectDate: prospectDate ? dayjs(prospectDate).toISOString() : null,
        beamerDate: beamerDate ? dayjs(beamerDate).toISOString() : null,
        pathSeekerDate: pathSeekerDate ? dayjs(pathSeekerDate).toISOString() : null,
        teacherDate: teacherDate ? dayjs(teacherDate).toISOString() : null,
        teachingStatus: values.teachingStatus
      };
      try {
        let res = await _userService.updateUser(updatedUser);
        navigate(".", { state: { userData: res } }); 
  
        messageApi.open({
          type: "success",
          content: "User profile updated successfully!",
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });
  
        setIsSaveDisabled(true);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: "Something went wrong. Please try again later.",
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });
        setIsLoading(false);

        if (error.code === 401) {
          localStorage.removeItem("loggedUser");
          await instance.logout({
            onRedirectNavigate: () => {
              return false;
            }
          });
          await instance.loginRedirect();
        }
      }
    }
  };

  const [form] = Form.useForm();

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="accountDetailsForm"
        onFinish={onFinish}
        initialValues={{
          teachingStatus: userData.teachingStatus,
        }}
      >
        <Row gutter={24}>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: "left",  }}>
            <span className="membershipLevelsTitle">Membership Level</span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Email Address (Username)"
              name="email"
              rules={[{ required: true, message: "Please enter your email" }]}
            >
              <Input
                onChange={handleFormChange}
                disabled
                defaultValue={email}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label=" "
              name="prospectDate"
            >
              <Space align="center" size={0}>
                <span className="prospect-block">Prospect</span>
                <DatePicker
                  onChange={onChangeProspectDate}
                  value={prospectDate}
                  disabled={!membershipLevelEnabled}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Current Password" name="currentPassword">
              <Input.Password
                name="currentPassword"
                disabled={!passwordEnabled}
                onChange={handleFormChange}
              ></Input.Password>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label=" "
              name="beamerDate"
            >
              <Space align="center" size={0}>
                <span className="beamer-block">Beamer</span>
                <DatePicker
                  value={beamerDate}
                  onChange={onChangeBeamerDate}
                  disabled={!membershipLevelEnabled}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="New Password" name="newPassword">
              <Input.Password
                name="newPassword"
                disabled={!passwordEnabled}
                onChange={handleFormChange}
              ></Input.Password>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label=" "
              name="pathSeekerDate"
            >
              <Space align="center" size={0}>
                <span className="pathSeeker-block">Pathseeker</span>
                <DatePicker
                  value={pathSeekerDate}
                  onChange={onChangePathSeekerDate}
                  disabled={!membershipLevelEnabled}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Confirm New Password"
              name="confirmNewPassword"
              dependencies={["newPassword"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match."
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                name="confirmNewPassword"
                disabled={!passwordEnabled}
              ></Input.Password>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label=" "
              name="teacherDate"
            >
              <Space  size={0}>
                <span className="teacher-block">Teacher</span>
                <DatePicker
                  value={teacherDate}
                  onChange={onChangeTeacherDate}
                  disabled={!membershipLevelEnabled}
                />
              </Space>
            </Form.Item>
          </Col>
          <Col span={6}>
            { teacherDate != null && teacherDate != "" && (
              <Form.Item 
                name="teachingStatus" 
                label="Teaching Status"
                rules={[
                  {
                    required: teacherDate ? true : false,
                    message: "This field is required"
                  },
                ]}
              >
                <Select
                  name="teachingStatus"
                  onChange={handleFormChange}
                  options={teacherStatusOptions}
                  disabled={!membershipLevelEnabled}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                size="middle"
                htmlType="submit"
                className="confirmButton"
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default AccountDetails;
