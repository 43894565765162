import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import moment from "moment";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Select,
  Form,
  Input,
  Row,
  Spin,
  message,
  Modal,
  DatePicker,
} from "antd";
import EventService from "../../../services/EventService";
import NoteService from "../../../services/NoteService";
import CreateNote from "../../../components/ClassesEvents/CreateNote";
import FetchClient from "../../../serviceClients/FetchClient";
import CompleteEventModal from "../../../components/ClassesEvents/CompleteEventModal";
import { ExclamationCircleFilled } from "@ant-design/icons";

function EventStatus({ eventData, setEventData, isSaveDisabled, setIsSaveDisabled }) {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [onOpenCompleteModal, setOnOpenCompleteModal] = useState(false);
  const [createNoteModal, setCreateNoteModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [error, setError] = useState({});
  const [note, setNote] = useState({});
  const [selectedAction, setSelectedAction] = useState({});
  const [date, setDate] = useState("");
  const [updateValues, setUpdateValues] = useState({});
  const [eventTypePreRecorded, setEventTypePreRecorded] = useState(
    eventData && (eventData.classType == "Pre-recorded DVD" || eventData.classType == "Pre-recorded On Demand")
  );
  const [selectedEvent, setSelectedEvent] = useState(location.state?.event);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const actionOptions = [
    { id: 1, label: "Cancel", value: "Cancelled" },
    { id: 2, label: "Complete", value: "Completed" },
    { id: 3, label: "Permit", value: "Permitted" },
    { id: 4, label: "Reject", value: "Rejected" },
    { id: 5, label: "Review", value: "In Review" },
  ];
  const statusOptions = [
    { id: 1, label: "Draft", value: "Draft" },
    { id: 2, label: "Open", value: "Open" },
    { id: 3, label: "Closed", value: "Closed" },
  ];

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    if (eventData && Object.keys(eventData).length > 0) {
      setEventTypePreRecorded(eventData && (eventData.classType === "Pre-recorded DVD" || eventData.classType === "Pre-recorded On Demand"));
      if (eventData.classType === "Pre-recorded DVD" || eventData.classType === "Pre-recorded On Demand") {
        form.setFieldsValue({
          action: eventData?.status,
        });
      } else {
        const today = dayjs(new Date())
        setDate(today);
        setCurrentStatus(eventData?.status);
        form.setFieldsValue({
          date: today,
          completionDate: eventData?.completionDate
            ? dayjs(eventData.completionDate).utc().format('MM/DD/YYYY')
            : null,
          cancellationDate: eventData?.cancellationDate
            ? dayjs(eventData.cancellationDate).utc().format('MM/DD/YYYY')
            : null,
          permitDate: eventData?.permitDate
            ? dayjs(eventData.permitDate).utc().format('MM/DD/YYYY')
            : null,
          rejectionDate: eventData?.rejectionDate
            ? dayjs(eventData.rejectionDate).utc().format('MM/DD/YYYY')
            : null,
        });
      }
    } else {
      fetchEvent();
    }
  }, []);

  async function fetchEvent() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const eventService = new EventService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const eventData = await eventService.getEvent(eventId);
      setEventData(eventData);

      if (eventData && (eventData.classType === "Pre-recorded DVD" || eventData.classType === "Pre-recorded On Demand")) {
        setEventTypePreRecorded(true);
      } else {
        setEventTypePreRecorded(false);
        const today = dayjs(new Date())
        form.setFieldsValue({
          date: today,
        });
        setDate(today);
      }
      // setEventTypePreRecorded(eventData && (eventData.classType === "Pre-recorded DVD" || eventData.classType === "Pre-recorded On Demand"));
      form.setFieldsValue(eventData);
    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  const onCloseNoteCreateModal = () => {
    setCreateNoteModal(false);
  };

  const onDateInputChange = (e) => {
    let tempDate = new Date(e);
    setDate(dayjs(tempDate).format("L"));
    isButtonDisabled();
  };

  const onEventTypeChange = (value) => {
    setSelectedAction(value);
    isButtonDisabled();
  };

  const createNote = async () => {
    setIsLoading(true);
    setNote({});
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);
    let createdNote = {};

    try {
      //CREATE USER
      let eventId = localStorage.getItem("eventId");
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      createdNote = await _noteService.createNote({
        eventId: eventId,
        note: note.text,
        createdBy: `${loggedUser.firstName} ${loggedUser.lastName}`,
        status: updateValues.action,
      });
      messageApi.open({
        type: "success",
        content: "Note created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      onCloseNoteCreateModal();
      updateEvent();
      setIsSaveDisabled(true);
      setIsLoading(false);
    }
  };

  const showConfirm = () => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `Are you sure you would like to update the status to: ${updateValues.action}?`,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        createNote();
      },
      onCancel() {
        onCloseNoteCreateModal();
      },
      width: 500,
    });
  };

  const showStatusConfirm = (action) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `Are you sure you would like to ${action} this class?`,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        updateEvent();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const handleCreateNoteInputChange = (e) => {
    const { name, value } = e.target;
    setNote({ ...note, [name]: value });
  };

  const onFinish = async (values) => {
    setUpdateValues(values);
    if (eventTypePreRecorded) {
      if (values.action === "Closed") {
        showStatusConfirm("close");
      } else if (values.action === "Open") {
        showStatusConfirm("open");
      } else if (values.action === "Draft") {
        updateEvent();
      }
    } else if (values.action === "Completed") {
      setOnOpenCompleteModal(true);
    } else if (
      values.action === "Permitted" ||
      values.action === "Rejected" ||
      values.action === "In Review" ||
      values.action === "Cancelled"
    ) {
      setCreateNoteModal(true);
    }
  };

  const updateEvent = async () => {
    setIsLoading(true);

    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _eventService = new EventService(_fetchClient);

    try {
      let res;
      const eventId = localStorage.getItem("eventId");
      let updateObject = {
        id: eventId,
      };
      let dateToSet = dayjs(date).toISOString();
      // let dateToSet = moment(date).
      let formUpdatedObject = form.getFieldsValue();
      if (updateValues.action == "Completed") {
        updateObject.status = "Completed";
        updateObject.completionDate = dateToSet;
        formUpdatedObject.completionDate = dateToSet;
        formUpdatedObject.action = "";
        setCurrentStatus("Completed");
      }
      else if (updateValues.action == "Cancelled") {
        updateObject.status = "Cancelled";
        updateObject.cancellationDate = dateToSet;
        formUpdatedObject.cancellationDate = dateToSet;
        formUpdatedObject.action = "";
        formUpdatedObject.date = "";
        form.setFieldsValue(formUpdatedObject);
        setCurrentStatus("Cancelled");
      }
      else if (updateValues.action == "Rejected") {
        updateObject.status = "Rejected";
        updateObject.rejectionDate = dateToSet;
        formUpdatedObject.rejectionDate = dateToSet;
        formUpdatedObject.action = "";
        formUpdatedObject.date = "";
        form.setFieldsValue(formUpdatedObject);
        setCurrentStatus("Rejected");
      }
      else if (updateValues.action == "Permitted") {
        updateObject.status = "Permitted";
        updateObject.permitDate = dateToSet;
        formUpdatedObject.permitDate = dateToSet;
        formUpdatedObject.action = "";
        formUpdatedObject.date = "";
        // form.setFieldsValue("status", "Permitted");
        form.setFieldsValue(formUpdatedObject);
        setCurrentStatus("Permitted");
      }
      else if (updateValues.action == "In Review") {
        updateObject.status = "In Review";
        formUpdatedObject.action = "";
        formUpdatedObject.date = "";
        form.setFieldsValue(formUpdatedObject);
        setCurrentStatus("In Review");
      }
      else if (formUpdatedObject.action == "Open") {
        updateObject.status = "Open";
      }
      else if (formUpdatedObject.action == "Closed") {
        updateObject.status = "Closed";
      }
      else if (formUpdatedObject.action == "Draft")  {
        updateObject.status = "Draft";
      }

      res = await _eventService.updateEvent(updateObject);
      let newEventData = { 
        ...res,
        schedules: eventData.schedules,
        facilitators: eventData.facilitators,
        teamFacilitators: eventData.teamFacilitators 
      };
      setEventData(newEventData);
      messageApi.open({
        type: "success",
        content: "Event profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
      setIsSaveDisabled(true);
    }
  };

  const isButtonDisabled = () => {
    const eventValues = form.getFieldsValue();
    if (!eventValues.action){
      setIsSaveDisabled(true);
      return;
    } else if (!eventTypePreRecorded && !eventValues.date)
      setIsSaveDisabled(true);
    else
      setIsSaveDisabled(false);
  }

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <CompleteEventModal
        updateEvent={updateEvent}
        onOpen={onOpenCompleteModal}
        onCancel={() => setOnOpenCompleteModal(false)}
        setOnOpenCompleteModal={setOnOpenCompleteModal}
        eventTypePreRecorded={eventTypePreRecorded}
      />
      <CreateNote
        onOpenCreateNoteModal={createNoteModal}
        onCancelCreateNoteModal={onCloseNoteCreateModal}
        onCreateNote={showConfirm}
        note={note}
        onInputChange={handleCreateNoteInputChange}
      />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="accountDetailsForm"
        onFinish={onFinish}
      >
        {!eventTypePreRecorded && (
          <Row gutter={24}>
            <Col span={6}>
              <span style={{ fontFamily: "Arial", fontSize: 14 }}>
                Current Status
              </span>
            </Col>
          </Row> 
        )}
        <Row style={{ marginTop: "5px", marginBottom: "10px" }} gutter={24}>
          <Col span={6}>
          {eventTypePreRecorded && (
            <Form.Item name="action" label="Current Status">
              <Select
                name="action"
                className="userListSearchBox"
                onChange={onEventTypeChange}
                options={statusOptions}
                allowClear
              ></Select>
            </Form.Item>
          )}
            {!eventTypePreRecorded && (
              <span
                style={{ fontFamily: "Arial", fontSize: 14, fontWeight: 700 }}
              >
                {currentStatus}
              </span>
            )}
          </Col>
          <Col span={18}>
            {!eventTypePreRecorded && (
              <span style={{ fontFamily: "Arial", fontSize: 14 }}>
                Please enter a date to update and select an Action to update
                status of event
              </span>
            )}
          </Col>
        </Row>
        {!eventTypePreRecorded && (
          <>
            <Row gutter={24}>
            <Col span={6}>
                <Form.Item 
                  name="action" 
                  label="Action"
                  rules={[
                    {
                      required: !eventTypePreRecorded,
                      message: "Action is required",
                    },
                  ]}
                >
                  <Select
                    name="action"
                    className="userListSearchBox"
                    onChange={onEventTypeChange}
                    options={actionOptions}
                    allowClear
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item 
                  name="date" 
                  label="Date"
                  rules={[
                    {
                      required: !eventTypePreRecorded,
                      message: "Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    // name="date"
                    className="userListSearchBox"
                    style={{ width: "100%" }}
                    onChange={onDateInputChange}
                  ></DatePicker>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="applicationDate" label="Application Date">
                  <Input
                    name="applicationDate"
                    className="userListSearchBox"
                    disabled
                    style={{ width: "100%" }}
                    defaultValue={
                      eventData?.creationDate
                        ? dayjs(eventData.creationDate).utc().format('MM/DD/YYYY')
                        : null
                    }
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name="permitDate" label="Permit Date">
                  <Input
                    name="permitDate"
                    className="userListSearchBox"
                    style={{ width: "100%" }}
                    disabled
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="rejectionDate" label="Rejection Date">
                  <Input
                    name="rejectionDate"
                    className="userListSearchBox"
                    style={{ width: "100%" }}
                    disabled
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="cancellationDate" label="Class/Event Cancellation Date">
                  <Input
                    name="cancellationDate"
                    className="userListSearchBox"
                    style={{ width: "100%" }}
                    disabled
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="completionDate" label="Completion Date">
                  <Input
                    name="completionDate"
                    className="userListSearchBox"
                    style={{ width: "100%" }}
                    disabled
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button 
                htmlType="submit" 
                className="confirmButton"
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default EventStatus;
