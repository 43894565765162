import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import dayjs from "dayjs";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ParticipantService from "../../../services/ParticipantService";
import FetchClient from "../../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Input,
  Space,
  Table,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { renderMatches, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import CreateParticipant from "../../../components/ClassesEvents/CreateParticipant";

function Participants({ setActiveTab, isPreRecorded, eventData }) {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [participant, setParticipant] = useState({
    feePaid: false,
    credit: false,
  });
  const [participants, setParticipants] = useState([]);
  const [allParticipants, setAllParticipants] = useState([]);
  const [editParticipant, setEditParticipant] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createParticipanteModal, setCreateParticipantModal] = useState(false);
  const [eventTypePreRecorded, setEventTypePreRecorded] = useState(eventData && (eventData.classType === "Pre-recorded DVD" || eventData.classType === "Pre-recorded On Demand"));
  const [error, setError] = useState();

  useEffect(() => {
    setEventTypePreRecorded(eventData && (eventData.classType === "Pre-recorded DVD" || eventData.classType === "Pre-recorded On Demand"))
  }, [
    eventData,
  ]);
  
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  //GET COURSE LIST
  async function fetchParticipants() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const participantService = new ParticipantService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const participantData = await participantService.getAllParticipants(
        eventId
      );
      setParticipants(participantData);
      setAllParticipants(participantData);

    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  async function deleteParticipant() {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _participantService = new ParticipantService(_fetchInstance);
    
    try {
      //DELETE USER
      for (const participant of selectedParticipants) {
        await _participantService.deleteParticipant(participant);
      }
      messageApi.open({
        type: "success",
        content: "Participant deleted successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setSelectedParticipants([]);
      setIsLoading(false);
      fetchParticipants();
    }
  }

  useEffect(() => {
    fetchParticipants();
  }, [
    activeAccount.idToken,
  ]);

  //HANDLE TABLE CHANGE

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = (record) => {
    setEditParticipant(record);
    setCreateParticipantModal(true);
  };

  //SET COURSE LIST TABLE COLUMNS
  const columns = [
    {
      title: "Participant",
      dataIndex: "userId",
      key: "userId",
      sorter: (a, b) => a.userId.localeCompare(b.userId),
      render: (_, record) => (
        <>
          <span>{record.user?.firstName + " " + record.user?.lastName}</span>
        </>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Fee Paid",
      dataIndex: "feePaid",
      key: "feePaid",
      render: (_, record) => (
        <>
          <span>{record.feePaid ? "Yes" : "No"}</span>
        </>
      ),
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      sorter: (a, b) => a.paymentDate.localeCompare(b.paymentDate),
      render: (_, record) => <>{dayjs(record.paymentDate).utc().format('MM/DD/YYYY')}</>
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (_, record) => (
        <>
          <span>{record.credit ? "Yes" : "No"}</span>
        </>
      ),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        eventData?.status !== "Closed" && (
          <Space size="middle">
            <PencilSquare
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleEditClick(record);
              }}
            />
          </Space>
        )
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you would like to delete these participants?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteParticipant();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCloseParticipantCreateModal = () => {
    setEditParticipant({});
    setParticipant({feePaid: false, credit: false,});
    setCreateParticipantModal(false);
  };

  const onCreateParticipant = async () => {
    setIsLoading(true);
    setEditParticipant({});
    setParticipant({feePaid: false, credit: false,});
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _participantService = new ParticipantService(_fetchInstance);
    let createdParticipant = {};

    try {
      //CREATE USER
      let eventId = localStorage.getItem("eventId");
      createdParticipant = await _participantService.createParticipant({
        ...participant,
        eventId: eventId,
      });
      messageApi.open({
        type: "success",
        content: "Participant created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      onCloseParticipantCreateModal();
      setIsLoading(false);
      fetchParticipants();
    }
  };
  const onEditParticipant = async () => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _participantService = new ParticipantService(_fetchInstance);
    let createdParticipant = {};

    try {
      //CREATE USER
      createdParticipant = await _participantService.updateParticipant(
        {
          ...participant, 
          id: editParticipant.id
        }
      );
      messageApi.open({
        type: "success",
        content: "Participant updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      onCloseParticipantCreateModal();
      setIsLoading(false);
      fetchParticipants();
    }
  };
  const handleCreateParticipantInputChange = (e) => {
    const { name, value, id } = e.target;
    setParticipant({ ...participant, [name]: id && id != "note" ? id : value });
  };

  const handleCreateParticipantSelectChange = (e) => {
    setParticipant({
      ...participant,
      userId: e,
    });
  };
  const handleCreateParticipantRoleSelectChange = (e) => {
    setParticipant({
      ...participant,
      role: e,
    });
  };
  const handleCreateParticipantDateInputChange = (e) => {
    const date = new Date(e);
    setParticipant({
      ...participant,
      paymentDate: dayjs(date).toISOString(),
    });
  };

  //HANDLE COURSE LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedParticipants(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Participant",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                direction="vertical"
                size="small"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                {eventData?.status !== "Closed" &&
                  <Button
                  onClick={() => setCreateParticipantModal(true)}
                  className="confirmButton"
                  style={{ marginBottom: "8px" }}
                  >
                    Add Participant +
                  </Button>
                }
                {selectedParticipants.length > 0 && (
                  <Button
                    onClick={showDeleteModal}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                  >
                    Delete
                  </Button>
                )}
              </Space>
              <Table
                rowClassName={"tableRowActiveParticipant"}
                rowSelection={eventData?.status !== "Closed" ? {type: "checkbox", ...rowSelection} : null}
                columns={columns}
                dataSource={participants}
                rowKey={(record) => record.id}
                pagination={false}
              />
              <CreateParticipant
                onOpenCreateParticipantModal={createParticipanteModal}
                onCancelCreateParticipantModal={onCloseParticipantCreateModal}
                onEditParticipant={onEditParticipant}
                onCreateParticipant={onCreateParticipant}
                onInputChange={handleCreateParticipantInputChange}
                onSelectChange={handleCreateParticipantSelectChange}
                onSelectRoleChange={handleCreateParticipantRoleSelectChange}
                onDateInputChange={handleCreateParticipantDateInputChange}
                setParticipant={setParticipant}
                participant={participant}
                participants={participants}
                allParticipants={participants}
                editParticipant={editParticipant}
              />
            </div>
          </Flex>
        </Flex>
        <Row style={{ marginTop: "20px" }} gutter={24}>
          <Col span={12}>
            <div style={{ textAlign: "left" }}>
              <Button onClick={() => setActiveTab(isPreRecorded ? 2 : 3)} className="confirmButton">
                Back
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => setActiveTab(5)}
                htmlType="submit"
                className="confirmButton"
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default Participants;
