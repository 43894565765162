import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useMsal } from "@azure/msal-react";
import { SubmitButton } from "../../../components/SubmitButton";
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Spin,
  DatePicker,
  message,
  Select,
} from "antd";
import { Header } from "antd/es/layout/layout";
import CustomHeader from "../../../components/Header";
import { Country, State } from "country-state-list";
import { ColorRing } from "react-loader-spinner";
import FacilitatorService from "../../../services/FacilitatorService";
import FetchClient from "../../../serviceClients/FetchClient";
import TeamMembers from "./TeamMembers";
const { TextArea } = Input;

function Facilitator() {
  const location = useLocation();
  const { instance } = useMsal();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [shippingStateDisabled, setShippingStateDisabled] = useState(true);
  const [isTeamCreated, setIsTeamCreated] = useState(false);
  const [statesList, setStatesList] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [countries, setCountries] = useState([]);
  const [teamId, setTeamId] = useState("");
  const [members, setMembers] = useState([]);
  const [teamData, setTeamData] = useState(JSON.parse(localStorage.getItem("facilitatorTeam")));
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    if (location.state && Object.keys(location.state.facilitatorTeam).length > 0) {
      const teamData = location.state.facilitatorTeam;
      setIsEditMode(true);
      form.setFieldsValue({
        ...teamData,
        startDate: dayjs(teamData.startDate),
        endDate: teamData.endDate ? dayjs(teamData.endDate) : null,
      });
      setTeamId(teamData.id);
      setMembers(teamData.teamMembers ?? []);
      setIsTeamCreated(true);

      // SET STATE DROPDOWN
      if (teamData.locationCountry?.toLowerCase() === "united states") {
        let states = State.getStatesOfCountry("US");
        setStatesList(
          states.map((state) => {
            return {
              label: state.name + " (" + state.isoCode + ")",
              value: state.name,
            };
          })
        );
        setShippingStateDisabled(false);
      } else if (teamData.locationCountry?.toLowerCase() === "canada") {
        let states = State.getStatesOfCountry("CA");
        setStatesList(
          states.map((state) => {
            return {
              label: state.name + " (" + state.isoCode + ")",
              value: state.name,
            };
          })
        );
        setShippingStateDisabled(false);
      } else {
        setStatesList([]);
        setShippingStateDisabled(true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (teamData && Object.keys(teamData).length > 0) {
      setIsEditMode(true);
      form.setFieldsValue({
        ...teamData,
        startDate: dayjs(teamData.startDate),
        endDate: teamData.endDate ? dayjs(teamData.endDate) : null,
      });
      setTeamId(teamData.id);
      setMembers(teamData.teamMembers ?? []);
      setIsTeamCreated(true);

      // SET STATE DROPDOWN
      if (teamData.locationCountry?.toLowerCase() === "united states") {
        let states = State.getStatesOfCountry("US");
        setStatesList(
          states.map((state) => {
            return {
              label: state.name + " (" + state.isoCode + ")",
              value: state.name,
            };
          })
        );
        setShippingStateDisabled(false);
      } else if (teamData.locationCountry?.toLowerCase() === "canada") {
        let states = State.getStatesOfCountry("CA");
        setStatesList(
          states.map((state) => {
            return {
              label: state.name + " (" + state.isoCode + ")",
              value: state.name,
            };
          })
        );
        setShippingStateDisabled(false);
      } else {
        setStatesList([]);
        setShippingStateDisabled(true);
      }
    }
  }, [teamData]);

  useEffect(() => {
    localStorage.removeItem("facilitatorTeam");
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.role === "admin") {
      setIsAdmin(true);
      if (countries != []) {
        setCountries(
          Country.getAllCountries().map((country) => {
            return {
              label: country.name,
              value: country.name
            }
          })
        );
      }
    } else {
      setIsAdmin(false);
    }
    setIsLoading(false);
  }, []);

  function onCountryChange(value) {
    form.setFieldsValue({ locationState: "" });
    if (value && value.toLowerCase() === "united states") {
      setShippingStateDisabled(false);
      let states = State.getStatesOfCountry("US");
      setStatesList(
        states.map((state) => {
          return {
            label: state.name + " (" + state.isoCode + ")",
            value: state.name,
          };
        })
      );
    } else if (value && value.toLowerCase() === "canada") {
      setShippingStateDisabled(false);
      let states = State.getStatesOfCountry("CA");
      setStatesList(
        states.map((state) => {
          return {
            label: state.name + " (" + state.isoCode + ")",
            value: state.name,
          };
        })
      );
    } else {
      setShippingStateDisabled(true);
      setStatesList([]);
    }
    isButtonDisabled();
  }

  const handleFormChange = () => {
    isButtonDisabled();
  };

  const onFinish = async () => {
    setIsLoading(true);
    let values = form.getFieldsValue();
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _facilitatorService = new FacilitatorService(_fetchClient);

    try {
      let res;
      if (isEditMode) {
        res = await _facilitatorService.updateFacilitator({
          ...values,
          startDate: dayjs(values.startDate).format("L"),
          endDate: values.endDate ? dayjs(values.endDate).format("L") : null,
          id: teamId,
        });
      } else {
        res = await _facilitatorService.createFacilitator({
          ...values,
          startDate: dayjs(values.startDate).format("L"),
          endDate: values.endDate ? dayjs(values.endDate).format("L") : null
        });
        setTeamId(res.id);
      }
      setIsEditMode(true);

      messageApi.open({
        type: "success",
        content: "Facilitator Team has been saved.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
      setIsSaveDisabled(true);
      setIsTeamCreated(true);
    }
  };

  const isButtonDisabled = () => {
    let facilitatorValues = form.getFieldsValue(); 
    if (!facilitatorValues.name || !facilitatorValues.startDate || !facilitatorValues.locationCountry || !facilitatorValues.locationCity)
      setIsSaveDisabled(true);
    else if ((facilitatorValues.locationCountry?.toLowerCase() === "united states" ||
      facilitatorValues.locationCountry?.toLowerCase() === "canada") && !facilitatorValues.locationState)
      setIsSaveDisabled(true);
    else
      setIsSaveDisabled(false)
  }

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (!isAdmin) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              <span style={{fontSize: 16, fontWeight: 'bold'}}>
                You are not authorized to access this page.
              </span>
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
        <CustomHeader
          items={
            isEditMode
              ? [{ title: "Classes and Events" }, { title: "Facilitator Teams", href: "/facilitatorTeams" }, { title: "Edit Facilitator Team" }]
              : [{ title: "Classes and Events" }, { title: "Facilitator Teams", href: "/facilitatorTeams" }, { title: "Add Facilitator Team" }]
          }
        />
      </Header>
      <Card style={{ padding: "20px" }} bordered={false}>
        {contextHolder}
        <Spin spinning={isLoading} fullscreen size="large" />
        <Form
          form={form}
          layout="vertical"
          size="large"
          name="facilitatorsForm"
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Team Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter a name for the team",
                  },
                ]}
              >
                <Input onChange={handleFormChange} defaultValue={""} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[
                  { required: true, message: "Please enter a start date" },
                ]}
              >
                <DatePicker
                  className="userListSearchBox"
                  onChange={handleFormChange}
                  style={{ width: "100%" }}
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="End Date"
                name="endDate"
              >
                <DatePicker
                  className="userListSearchBox"
                  onChange={handleFormChange}
                  style={{ width: "100%" }}
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="locationCountry"
                rules={[{ required: true, message: "Please enter a country" }]}
              >
                <Select
                  name="country"
                  showSearch
                  optionFilterProp="label"
                  className="userListSearchBox"
                  onChange={onCountryChange}
                  options={countries}
                  allowClear
                ></Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="City"
                name="locationCity"
                rules={[{ required: true, message: "Please enter a city" }]}
              >
                <Input onChange={handleFormChange} defaultValue={""} />
              </Form.Item>
            </Col>
            {!shippingStateDisabled && (
              <Col span={6}>
                <Form.Item
                  label="State"
                  name="locationState"
                  rules={[{ required: true, message: "Please enter a state" }]}
                >
                  <Select
                    name="state"
                    className="userListSearchBox"
                    showSearch
                    optionFilterProp="label"
                    onChange={handleFormChange}
                    options={statesList}
                    allowClear
                  ></Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Note" name="note">
                <TextArea
                  style={{ height: "100px" }}
                  name="note"
                  placeholder="Note"
                  onChange={handleFormChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <div style={{ textAlign: "right" }}>
                <Button
                  size="middle"
                  htmlType="submit"
                  className="confirmButton"
                  disabled={isSaveDisabled}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        {isTeamCreated && <TeamMembers members={members} teamId={teamId} />}
      </Card>
    </>
  );
}

export default Facilitator;
