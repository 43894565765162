const config = require("../config");

class EventService {
  constructor(httpClient) {
    this.BASE_URL = config.BASE_URL;
    this.httpClient = httpClient;
  }

  async getAllEvents() {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetAllEvents?"
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch events.");
      }

      const events = await response.json();
      return events;
    } catch (error) {
      console.error("Error fetching events: ", error);
      if (error.status === 401) {
        throw error;
      } else {
        return [];
      }
    }
  }

  async getAllEventsById(userId) {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetEventsById?userId=" + userId
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch events.");
      }

      const events = await response.json();
      return events;
    } catch (error) {
      console.error("Error fetching events: ", error);
      if (error.status === 401) {
        throw error;
      } else {
        return [];
      }
    }
  }

  async getEvent(eventId) {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetEvent?eventId=" + eventId
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch event data.");
      }

      const user = await response.json();
      return user;
    } catch (error) {
      console.error("Error fetching event: ", error);
      if (error.status === 401) {
        throw error;
      } else {
        return null;
      }
    }
  }


  async createEvent(event) {    
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/CreateEvent",
        event
      );
      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async batchUpdateEvents(eventsActionModel) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/BatchEventUpdate",
        eventsActionModel
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateEvent(event) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/UpdateEvent",
        event
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async deleteEvent(event) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/DeleteEventById",
        event
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updatePassword(passwordModel) {
    try {
      await this.httpClient.post(
        this.BASE_URL + "/UpdatePassword",
        passwordModel
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default EventService;
