const config = require("../config");

class ParticipantService {
  constructor(httpClient) {
    this.BASE_URL = config.BASE_URL;
    this.httpClient = httpClient;
  }

  async getAllParticipants(eventId) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/GetAllParticipants?",
        eventId
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch participants.");
      }

      const participants = await response.json();
      return participants;
    } catch (error) {
      console.error("Error fetching participants: ", error);
      if (error.status === 401) {
        throw error;
      } else {
        return [];
      }
    }
  }


  async createParticipant(participant) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/CreateParticipant",
        participant
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async batchUpdateParticipants(participantsActionModel) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/BatchParticipantUpdate",
        participantsActionModel
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateParticipant(participant) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/UpdateParticipant",
        participant
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async deleteParticipant(participant) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/DeleteParticipantById",
        participant
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default ParticipantService;
