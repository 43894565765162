import { MsalProvider, AuthenticatedTemplate } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { Flex, Layout } from "antd";
import Sidebar from "./components/Sidebar";

import CustomHeader from "./components/CustomHeader";
import LandingComponent from "./components/LandingComponent";

import "./App.css";
import FetchClient from "./serviceClients/FetchClient";
import UserService from "./services/UserService";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(localizedFormat);
dayjs.extend(utc);


const { Sider, Header, Content } = Layout;
function App({ instance }) {
  const [loggedUser, setLoggedUser] = useState(null);
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    if (activeAccount != null) {
      getLoggedUser();
    }
  }, []);

  const getLoggedUser = async () => {
    try {
      const _fetchInstance = new FetchClient(activeAccount.idToken);
      const _userService = new UserService(_fetchInstance);
      var user = await _userService.getUserByEmail(
        activeAccount.idTokenClaims.emails[0]
      );
      if (user) {
        setLoggedUser(user);
        localStorage.setItem("loggedUser", JSON.stringify(user));
      }
    } catch (error) {
      console.log("App.js error: ", error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  }

  return (
    <MsalProvider instance={instance}>
      <AuthenticatedTemplate>
        <Layout>
          <Header className="header">
            <CustomHeader />
          </Header>
          {/* <Header className="subheader">
            <CustomSubHeader />
          </Header> */}
          <Layout>
            <Sider width={250} theme="light" trigger={null} className="sider">
              <Sidebar parentActiveAccount={activeAccount} loggedUser={loggedUser} setLoggedUser={setLoggedUser} />
            </Sider>
            <Content className="content">
              <Flex gap="large">
                <LandingComponent />
              </Flex>
            </Content>
          </Layout>
        </Layout>
      </AuthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
