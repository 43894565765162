import { Button, Form, Modal, Table, Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import FetchClient from "../../serviceClients/FetchClient";
import ParticipantService from "../../services/ParticipantService";
import NoteService from "../../services/NoteService";
import "react-phone-input-2/lib/style.css";
import { useMsal } from "@azure/msal-react";

function CompleteEventModal({ updateEvent, onOpen, onCancel, onCreateNote, setOnOpenCompleteModal, eventTypePreRecorded }) {
  const { instance } = useMsal();
  const [participants, setParticipants] = useState([]);
  const [modifiedParticipants, setModifiedParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [nameSearchText, setNameSearchText] = useState("");
  const [form] = Form.useForm();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
      showTotal: (total, range) =>
        `Viewing ${range[0]}-${range[1]} of ${total} results`,
      pageSizeOptions: [10, 20, 50, 100],
    },
  });

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const cancelModal = () => {
    onCancel();
  };

  const onCompleteEvent = () => {
    if (!eventTypePreRecorded) {
      updateEvent();
      updateParticipants();
      createNote()
      setOnOpenCompleteModal(false)
    } else {
      updateEvent();
      updateParticipants();
      setOnOpenCompleteModal(false);
    }
  };

  const updateParticipants = async () => {
    try {
      const participantsToUpdate = modifiedParticipants.filter(
        (participant) => participant.credit != participant.originalCredit
      );
      
      let participantsPromises =[]
      for (const participant of participantsToUpdate) {
        participantsPromises.push(createUpdateParticipantPromise(participant))
      }
      const promisesResult = await Promise.all(participantsPromises);
    } catch (error) {
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
      console.log("Error updating participants", error);
    }
    
  };
  const createUpdateParticipantPromise = (participant) => {
    return new Promise (async(res, rej) => {
      const _fetchInstance = new FetchClient(activeAccount.idToken);
      //CREATE COURSE SERVICE INSTANCE
      const participantService = new ParticipantService(_fetchInstance);
  
      try {
        const participantData = await participantService.updateParticipant({
          id: participant.id,
          credit: participant.credit ? "1" : "0"
        });
        res(participantData)
      } catch (error) {
        rej(error);
      }
    })
  };

  const createNote = async () => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);
    let createdNote = {};

    try {
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      let eventId = localStorage.getItem("eventId");
      createdNote = await _noteService.createNote({
        status: "Completed",
        note: "Status Completed",
        createdBy: loggedUser.firstName + " " + loggedUser.lastName,
        eventId: eventId,
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  };

  const checkboxChange = (e, record) => {

    setModifiedParticipants([
      ...modifiedParticipants.filter(participant => participant.id != record.id),
      {
        id: record.id,
        credit: e.target.checked,
        originalCredit: record.credit && record.credit == "1",
      },
    ]);
  };
  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setParticipants([]);
    }
  };

  const columns = [
    {
      title: "Participant",
      dataIndex: "userId",
      key: "userId",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.userId?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.userId.localeCompare(b.userId),
      render: (_, record) => (
        <>
          <span>{record.user?.firstName + " " + record.user?.lastName}</span>
        </>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.role?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Fee Paid",
      dataIndex: "feePaid",
      key: "feePaid",
      filteredValue: [nameSearchText],
      render: (_, record) => (
        <>
          <span>{record.feePaid ? "Yes" : "No"}</span>
        </>
      ),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      filteredValue: [nameSearchText],
      render: (_, record) => (
        <>
          <Checkbox
            onChange={(e) => checkboxChange(e, record)}
            defaultChecked={record.credit && record.credit !== "0"}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (onOpen) {
      fetchParticipants();
    }
  }, [onOpen]);

  //GET COURSE LIST
  async function fetchParticipants() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const participantService = new ParticipantService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const participantData = await participantService.getAllParticipants(
        eventId
      );
      setParticipants(participantData);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: participantData.length,
        },
      });
    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={eventTypePreRecorded ? "Close Pre-recorded Item" : "Complete Event"}
        centered
        destroyOnClose={true}
        open={onOpen}
        onOk={onCreateNote}
        onCancel={() => cancelModal()}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => cancelModal()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="confirmButton"
            onClick={onCompleteEvent}
            disabled={isLoading}
          >
            {"Complete"}
          </Button>,
        ]}
      >
        <span>Please check each participant that will receive credit for this event</span>
        <Table
          className="modal-table"
          columns={columns}
          dataSource={participants}
          rowKey={(record) => record.id}
          pagination={false}
          onChange={handleTableChange}
          scroll={{ y: 200 }}
        />
      </Modal>
    </div>
  );
}

export default CompleteEventModal;
