import { Flex, Breadcrumb } from "antd";
import { React } from "react";

function CustomHeader({items}) {
  return (
    <>
      <Flex align="center" justify="space-between">
        <Flex justify="flex-start" align="center">
          <Breadcrumb
            separator=">"
            items={items}
          />
        </Flex>
      </Flex>
    </>
  );
}

export default CustomHeader;
