import React from "react";
import ReactDOM from "react-dom/client";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";

import { msalConfig } from "./auth/authConfig";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.css";
import "./index.css";

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();  

const accounts = msalInstance.getAllAccounts();
const currentAccount = msalInstance.getActiveAccount();
if ( currentAccount && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
  // sessionStorage.setItem("loggedUser", msalInstance);
}
else {
  msalInstance.handleRedirectPromise().then(authResult=>{
    msalInstance.loginRedirect();
    // const account = msalInstance.getActiveAccount();
    // if(!account){
    //   msalInstance.loginRedirect();
    // }
  }).catch(err=>{
    console.log(err);
  });
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    const authResult = event.payload;
    msalInstance.setActiveAccount(authResult.account);
  }
});

// msalInstance.handleRedirectPromise().then(authResult=>{
//   // Check if user signed in 
//   const account = msalInstance.getActiveAccount();
//   if(!account){
//     // redirect anonymous user to login page 
//     msalInstance.loginRedirect();
//   }
// }).catch(err=>{
//   // TODO: Handle errors
//   console.log(err);
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App instance={msalInstance} />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
