import React, { useState, useEffect } from "react";
import {
  Card,
  Flex
} from "antd";
import { Header } from "antd/es/layout/layout";
import CustomHeader from "../../components/Header";
import { ColorRing } from "react-loader-spinner";
import { useMsal } from "@azure/msal-react";

function CodeFormat() {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.role === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [activeAccount.idToken]);

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (!isAdmin) {
    return (
      <Card>
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              <span style={{fontSize: 16, fontWeight: 'bold'}}>
                You are not authorized to access this page.
              </span>
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
        <CustomHeader
          items={[{ title: "Classes & Events", href: "/classesAndEventsList" }, { title: "CTI Code Format" }]}
        />
      </Header>
      <Card style={{ padding: "20px" }}>
        <Flex vertical gap="30px">
          <div>
            <div style={{ padding: "10px" }}>
              <h3>CTI ID Format</h3>
              <h4><strong>Format</strong>: EE-CC-YYMMDD-FF-RND#</h4>
              <div style={{marginTop: '8px'}}>
                <div><strong>EE = Event Type</strong></div>
                <div>If Event Type is a Class, set EE to CL</div>
                <div>If Event Type is an Event, set EE to EV</div>
              </div>
              <div style={{marginTop: '8px'}}>
                <div><strong>CC = Code (Code assigned to Course or Event)</strong></div>
                <div>Example: If Course is "Conceptology Phase 1" set CC to PH1</div>
              </div>
              <div style={{marginTop: '8px'}}>
                <div><strong>YY = Year. Example 24 for 2024</strong></div>
                <div><strong>MM = Month (01-12), Example 06 for June</strong></div>
                <div><strong>DD = Day (01-31)</strong></div>
              </div>
              <div style={{marginTop: '8px'}}>
                <div><strong>FF = Class Format Type</strong></div>
                <div>The following are the codes for the different types of format for classes:</div>
                <div>PRD : Pre-recorded DVD</div>
                <div>PROD: Pre-recorded On Demand</div>
                <div>IP: In Person Class</div>
                <div>LS: Live Streamed</div>
                <div>HY: Hybrid</div>
              </div>
              <div style={{marginTop: '8px'}}>
                <div><strong>RND# : Auto-generated 3 character random number (e.g., "000")</strong></div>
              </div>
            </div>
          </div>
        </Flex>
      </Card>
    </>
  );
}
export default CodeFormat;