export const membershipOptions = [
  {
    label: "Expired",
    value: "Expired",
  },
  {
    label: "Member",
    value: "Member",
  },
  {
    label: "Non-Member",
    value: "Non-Member",
  },
];

export const roleOptions = [
  {
    label: "User",
    value: "user",
  },
  {
    label: "System Admin",
    value: "admin",
  },
  {
    label: "Club Administrator",
    value: "clubadmin",
  },
  {
    label: "Teaching Coordinator",
    value: "teachingcoordinator",
  },
];
export const familyStatusOptions = [
  {
    label: "Head of Household",
    value: "Head of Household",
  },
  {
    label: "Family Member",
    value: "Family Member",
  },
  {
    label: "None",
    value: "None",
  },
];
export const prospectSourceOptions = [
  {
    label: "CTI Function",
    value: "ctiFunction",
  },
  {
    label: "Email Inquiry",
    value: "email",
  },
  {
    label: "Magazine",
    value: "magazine",
  },
  {
    label: "Online Store",
    value: "online",
  },
  {
    label: "Personal Referral",
    value: "personal",
  },
  {
    label: "Telephone Inquiry",
    value: "telephone",
  },
  {
    label: "Website Signup",
    value: "website",
  },
];

export const titleOptions = [
  {
    value: "DC",
    label: "DC",
  },
  {
    value: "Dr",
    label: "Dr",
  },

  {
    value: "Miss",
    label: "Miss",
  },
  {
    value: "Mr",
    label: "Mr",
  },
  {
    value: "Mrs",
    label: "Mrs",
  },
  {
    value: "Ms",
    label: "Ms",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const teacherStatusOptions = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
  {
    label: "Teacher Training Graduate",
    value: "Teacher Training Graduate",
  },
]

export const countryOptions = [
  {
    value: "usa",
    label: "United States",
  },
  {
    value: "can",
    label: "Canada",
  },
  {
    value: "mex",
    label: "Mexico",
  },
];

export const accountLevelOptions = [
  { value: "prospect", label: "Prospect" },
  { value: "beamer", label: "Beamer" },
  { value: "pathSeeker", label: "Pathseeker" },
  { value: "teacher", label: "Teacher" },
];

export const activeUserOptions = [
  { label: "Disable Users", key: "disable" },
  {
    label: "Download Report",
    key: "download",
  },
];

export const inactiveUserOptions = [
  { label: "Enable Users", key: "enable" },
  {
    label: "Download Report",
    key: "download",
  },
];

export const noUserSelectedOptions = [
  {
    label: "Download Report",
    key: "download",
  },
];

export const activeInactiveUserOptions = [
  {
    label: "Download Report",
    key: "download",
  },
];
