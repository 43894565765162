const config = require("../config");

class ScheduleService {
  constructor(httpClient) {
    this.BASE_URL = config.BASE_URL;
    this.httpClient = httpClient;
  }

  async getAllSchedules(eventId) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/GetAllSchedules?",
        eventId
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch schedules.");
      }

      const schedules = await response.json();
      return schedules;
    } catch (error) {
      console.error("Error fetching schedules: ", error);
      if (error.status === 401) {
        throw error;
      } else {
        return [];
      }
    }
  }


  async createSchedule(schedule) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/CreateSchedule",
        schedule
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async batchUpdateSchedules(schedulesActionModel) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/BatchScheduleUpdate",
        schedulesActionModel
      );

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateSchedule(schedule) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/UpdateSchedule",
        schedule
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async deleteSchedule(schedule) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/DeleteScheduleById",
        schedule
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default ScheduleService;
