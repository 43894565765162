import { Button, Form, Input, Modal, Select, DatePicker, Space } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import dayjs from "dayjs";
import "react-phone-input-2/lib/style.css";
const { TextArea } = Input;

function CreateDue({
  onOpenCreateDueModal,
  onCancelCreateDueModal,
  onCreateDue,
  onEditDue,
  onInputChange,
  setDue,
  due,
  editDue,
  note,
}) {
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [type, setType] = useState("");
  const types = [
    { label: "Family", value: "Family" },
    { label: "Family 2 Year", value: "Family 2 Year" },
    { label: "Individual", value: "Individual" },
    { label: "Individual 2 Year", value: "Individual 2 Year" },
    { label: "Lifetime", value: "Lifetime" },
  ];

  const cancelModal = () => {
    setType("");
    form.resetFields();
    onCancelCreateDueModal();
  };

  const onTypeChange = (value) => {
    setType(value);
    setIsSaveDisabled(false);
    setDue({ ...due, type: value, expiryDate: "", dateEffective: "" });
    form.setFieldsValue({ expiryDate: "", dateEffective: "" });
  };

  const onDateChange = (date) => {
    let calculatedExpiryDate =  dayjs(new Date(calculateExpiryDate(type, date)))
    form.setFieldValue("expiryDate", calculatedExpiryDate);
    setDue({ ...due, expiryDate: dayjs(calculatedExpiryDate).toISOString(), dateEffective: dayjs(new Date(date)).toISOString() });
  };

  useEffect(() => {
    setIsEditMode(editDue && Object.keys(editDue).length > 0);
    if (editDue && Object.keys(editDue).length > 0) {
      setDue(editDue)
      form.setFieldsValue({
        type: editDue.type,
        dateEffective: dayjs(new Date(editDue.dateEffective)),
        expiryDate: dayjs(new Date(editDue.expiryDate)),
      });
    }
  }, [editDue]);

  function calculateExpiryDate(membershipType, effectiveDate) {
    const effectiveYear = new Date(effectiveDate).getUTCFullYear();
    const expiryDate = new Date(); // Create a new date for expiry

    // Helper function to set expiry date to December 31st of a specific year
    const setExpiryToDec31 = (year) => {
        expiryDate.setFullYear(year);
        expiryDate.setMonth(11); // December
        expiryDate.setDate(31); // 31st
    };

    if (membershipType === "Family" || membershipType === "Individual") {
        if (effectiveDate < new Date(effectiveYear, 9, 1)) { // Before October 1st
            setExpiryToDec31(effectiveYear);
        } else { // On or after October 1st
            setExpiryToDec31(effectiveYear + 1);
        }
    } else if (membershipType === "Family 2 Year" || membershipType === "Individual 2 Year") {
        if (effectiveDate < new Date(effectiveYear, 9, 1)) { // Before October 1st
            setExpiryToDec31(effectiveYear + 1);
        } else { // On or after October 1st
            setExpiryToDec31(effectiveYear + 2);
        }
    } else if (membershipType === "Lifetime") {
        setExpiryToDec31(2222);
    }

    return expiryDate;
}


  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Due" : "New Due"}
        centered
        destroyOnClose={true}
        open={onOpenCreateDueModal}
        onOk={isEditMode ? onEditDue : onCreateDue}
        onCancel={() => cancelModal()}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => cancelModal()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={isEditMode ? onEditDue : onCreateDue}
            ghost
            style={{ borderColor: "#26225A", color: "#26225A" }}
          >
            {isEditMode ? "Save" : "Add Due"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Type" name="type" required>
            <Select options={types} onChange={onTypeChange}></Select>
          </Form.Item>
          <div
            size="small"
            align="center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Form.Item
              style={{
                width: "100%",
              }}
              label="Date Effective"
              name="dateEffective"
              required
            >
              <DatePicker disabled={!type || type == ""} onChange={onDateChange} style={{
                width: "100%",
              }} />
            </Form.Item>
            <Form.Item
              style={{
                width: "100%",
              }}
              label="Expiry Date"
              name="expiryDate"
              required
            >
              <DatePicker disabled style={{
                width: "100%",
              }} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateDue;
