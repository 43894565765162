import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import FacilitatorService from "../../services/FacilitatorService";
import FetchClient from "../../serviceClients/FetchClient";
import NoteShow from "./NoteShow";
import {
  Card,
  Flex,
  Input,
  Select,
  Space,
  Table,
  Button,
  DatePicker,
  message,
} from "antd";
import { Header } from "antd/es/layout/layout";
import CustomHeader from "../../components/Header";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

function FacilitatorTeams() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { confirm } = Modal;
  const { RangePicker } = DatePicker;
  const [messageApi, contextHolder] = message.useMessage();
  const [facilitatorTeams, setFacilitatorTeams] = useState([]);
  const [allFacilitatorTeams, setAllFacilitatorTeams] = useState([]);
  const [onOpenShowNoteModal, setOnOpenShowNoteModal] = useState(false);
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [filter, setFilter] = useState("");
  const [error, setError] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      pageSizeOptions: [10, 20, 50, 100],
    },
  });
  const [selectedFacilitatorTeams, setSelectedFacilitatorTeams] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  async function fetchFacilitatorTeams() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const facilitatorTeamService = new FacilitatorService(_fetchInstance);

    try {
      const facilitatorTeamData =
        await facilitatorTeamService.getAllFacilitators();
      setFacilitatorTeams(facilitatorTeamData);
      setAllFacilitatorTeams(facilitatorTeamData);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination
        },
      });
    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  // useEffect(() => {
  //   fetchFacilitatorTeams();
  // }, [
  //   activeAccount.idToken,
  //   tableParams.pagination?.current,
  //   tableParams.pagination?.pageSize,
  // ]);

  function showDeleteModal() {
    showConfirm();
  }
  async function deleteFacilitator() {
    setIsLoading(true);
    // setSelectedFacilitatorTeams([]);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _facilitatorService = new FacilitatorService(_fetchInstance);

    try {
      for (const facilitatorTeam of selectedFacilitatorTeams) {
        await _facilitatorService.deleteFacilitator(facilitatorTeam);
      }
      // await _facilitatorService.deleteFacilitator(selectedFacilitatorTeams[0]);
      messageApi.open({
        type: "success",
        content: "Facilitator team has been deleted.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setSelectedFacilitatorTeams([]);
      setIsLoading(false);
      fetchFacilitatorTeams();
    }
  }

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.role === "admin") {
      setIsAdmin(true);
      fetchFacilitatorTeams();
    } else {
      setIsAdmin(false);
    }
  }, [activeAccount.idToken]);

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setFacilitatorTeams([]);
    // }
  };

  const openNoteModal = (note) => {
    setNote(note);
    setOnOpenShowNoteModal(true);
  };

  const onCancelShowNoteModal = () => {
    setNote("");
    setOnOpenShowNoteModal(false);
  };

  const handleEditClick = async (record) => {
    await localStorage.setItem("facilitatorTeamId", record.id);
    navigate("/addFacilitatorTeam", {
      state: {
        facilitatorTeam: record,
      },
    });
  };

  //SET EVENT LIST TABLE COLUMNS
  const columns = [
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Location",
      dataIndex: "locationCity",
      key: "locationCity",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Note",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => openNoteModal(record.note)}>View</Button>
        </Space>
      ),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this facilitator team?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteFacilitator();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (!isAdmin) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              <span style={{fontSize: 16, fontWeight: 'bold'}}>
                You are not authorized to access this page.
              </span>
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
        <CustomHeader
          items={[{ title: "Classes & Events", href: "/classesAndEventsList" }, { title: "Facilitator Teams" }]}
        />
      </Header>
      <NoteShow
        onOpenShowNoteModal={onOpenShowNoteModal}
        onCancelShowNoteModal={onCancelShowNoteModal}
        note={note}
      />
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                size="small"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  onClick={() => {
                    localStorage.removeItem("facilitatorTeamId");
                    navigate("/addFacilitatorTeam");
                  }}
                  className="confirmButton"
                  style={{ marginBottom: "8px" }}
                  >
                  Add Facilitator Team +
                </Button>
                {/* {selectedFacilitatorTeams.length === 1 && (
                  <Button
                    onClick={showDeleteModal}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                  >
                    Delete
                  </Button>
                )} */}
              </Space>
              <Table
                rowClassName={"tableRowActiveCourse"}
                // rowSelection={false}
                columns={columns}
                dataSource={facilitatorTeams}
                rowKey={(record) => record.id}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            </div>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
export default FacilitatorTeams;
