import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { titleOptions } from "../../shared/selectOptions";
import { useMsal } from "@azure/msal-react";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";

function PersonalInformation({
  userData,
  isSaveDisabled,
  setIsSaveDisabled,
  loggedUser,
}) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [otherFieldVisible, setOtherFieldVisible] = useState(userData.title == "Other" ? "inline-block"  : "none");
  const [isFormEditable, setIsFormEditable] = useState(true);

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();

    //TODO: CHECK USER ROLE FOR PERMISSIONS
  }

  const handleTitleChange = (value) => {
    setIsSaveDisabled(false);
    if (value.toUpperCase() === "OTHER") {
      setOtherFieldVisible("inline-block");
    } else {
      form.setFieldsValue( { otherTitle: "" } )
      setOtherFieldVisible("none");
    }
  };

  const handleFormChange = () => {
    setIsSaveDisabled(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    //Call Service to update user data
    //BUILD USER OBJECT
    const updatedUser = {
      email: userData.email,
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      mobileNumber: values.mobileNumber,
      alternatePhoneNumber: values.alternateNumber,
      otherTitle: values.otherTitle,
      suffix: values.suffix,
    };

    const _fetchClient = new FetchClient(activeAccount.idToken);
    //CREATE USER SERVICE INSTANCE
    const _userService = new UserService(_fetchClient);

    try {
      //CALL USER SERVICE API
      const resultUser = await _userService.updateUser(updatedUser);
      navigate(".", { state: { userData: resultUser } });

      //SHOW SUCCESS NOTIFICATION MESSAGE
      messageApi.open({
        type: "success",
        content: "User profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsSaveDisabled(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  };

  return (
    <Card style={{ padding: "20px" }}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="personalInformationForm"
        onFinish={onFinish}
        initialValues={{
          title: userData.title,
          otherTitle: userData.otherTitle,
          firstName: userData.firstName,
          middleName: userData.middleName,
          lastName: userData.lastName,
          suffix: userData.suffix,
          mobileNumber: userData.mobileNumber,
          alternateNumber: userData.alternatePhoneNumber,
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <Form.Item
                label="Title"
                name="title"
                style={{
                  display: "inline-block",
                  width: "30%",
                }}
              >
                <Select
                  onChange={handleTitleChange}
                  options={titleOptions}
                  disabled={!isFormEditable}
                ></Select>
              </Form.Item>
              <Form.Item
                name="otherTitle"
                label="Other"
                style={{
                  display: otherFieldVisible,
                  width: "60%",
                  margin: "0 16px",
                }}
              >
                <Input
                  name="otherTitle"
                  onChange={handleFormChange}
                  disabled={!isFormEditable}
                ></Input>
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="First Name" name="firstName">
              <Input
                onChange={handleFormChange}
                disabled={!isFormEditable}
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Middle Name" name="middleName">
              <Input onChange={handleFormChange} disabled={!isFormEditable} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Last Name" name="lastName">
              <Input
                name="lastName"
                onChange={handleFormChange}
                disabled={!isFormEditable}
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Suffix" name="suffix">
              <Input name="suffix" onChange={handleFormChange} disabled={!isFormEditable} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Mobile Number" name="mobileNumber">
              <PhoneInput
                country={"us"}
                preferredCountries={["us"]}
                countryCodeEditable={false}
                enableSearch={true}
                inputProps={{ name: "mobileNumber" }}
                value={userData.mobileNumber}
                onChange={handleFormChange}
                disabled={!isFormEditable}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Alternate Number" name="alternateNumber">
              <PhoneInput
                country={"us"}
                preferredCountries={["us"]}
                countryCodeEditable={false}
                enableSearch={true}
                inputProps={{ name: "alternateNumber" }}
                value={userData.alternatePhoneNumber}
                onChange={handleFormChange}
                disabled={!isFormEditable}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <div style={{ textAlign: "right" }}>
              <Button
                size="middle"
                htmlType="submit"
                className="confirmButton"
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default PersonalInformation;
