import React, { useEffect, useState } from "react";
import FetchClient from "../serviceClients/FetchClient";
import { AdminMenuItems, MenuItems } from "./SidebarOptions";
import { Menu, Spin, message } from "antd";
import { useNavigate } from "react-router-dom";
import CreateUser from "./Admin/User/CreateUser";
import UserService from "../services/UserService";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import SharedServices from "../services/SharedServices";
import { fillTemplate } from "../shared/utils";
import { newUserEmailTemplate } from "../shared/templates/newUserEmailTemplate";
import { newUserResetPasswordLink } from "../shared/constants";

function Sidebar({ parentActiveAccount, loggedUser, setLoggedUser }) {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const navigate = useNavigate();
  const [createUserModal, setCreateUserModal] = useState(false);

  ///MENU NAVIGATION METHOD
  const onMenuItemClick = (e) => {
    if (e.key === "/addUser") {
      //OPEN CREATE USER MODAL
      setCreateUserModal(true);
    } else if (e.key === "/myProfile") {
      //NAVIGATE TO COMPONENT
      navigate("/myProfile", {
        state: {
          userData: {
            email: activeAccount.username,
            title: "",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            alternatePhoneNumber: "",
            shippingCountry: "",
            shippingAddress: "",
            shippingCity: "",
            shippingState: "",
            shippingZipCode: "",
            billingCountry: "",
            billingAddress: "",
            billingCity: "",
            billingState: "",
            suffix: "",
            billingZipCode: "",
          },
        },
      });
      window.location.reload();
    } else {
      //NAVIGATE TO COMPONENT
      navigate(e.key);
    }
  };

  useEffect(() => {
    setLoggedUser(JSON.parse(localStorage.getItem("loggedUser")));
  },[])
  
  useEffect(() => {
    setTimeout(() => {
      if (activeAccount && !parentActiveAccount) {
        setLoggedUser(JSON.parse(localStorage.getItem("loggedUser")));
        window.location.reload()
      }
    }, 1000);
  }, [activeAccount])

  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    title: "",
    shippingCity: "",
    shippingCountry: "",
  });

  const onCloseUserCreateModal = () => {
    setUser({});
    setCreateUserModal(false);
  };

  const isEmailValid = (email) => {
    // let regex = /[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9._-]@[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9]\\.[a-zA-Z]{2,63}/;
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ((email.match(regex) || []).length > 0)
      return true; 
    else 
      return false;
  };

  const onCreateUser = async () => {
    setIsLoading(true);
    let valid = isEmailValid(user.email.trim().toLowerCase());
    if (!valid) {
      messageApi.open({
        type: "error",
        content: "Please enter a valid email address.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
      return;
    }

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _userService = new UserService(_fetchInstance);
    const _sharedService = new SharedServices(_fetchInstance);

    try {
      //CREATE USER
      const createdUser = await _userService.createUser(user);
      if (createdUser === null) {
        throw {
          error: 'UserAlreadyExists',
          message: 'User already exist in the system.'
        }
      }
      setUser(createdUser);

      //BUILD TEMPLATE DATA
      var templateData = {
        firstName: createdUser.firstName,
        lastName: createdUser.lastName,
        resetPasswordLink: newUserResetPasswordLink[process.env.NODE_ENV],
      };

      //SEND CONFIRMATION EMAIL TO NEW USER
      const emailTemplate = fillTemplate(newUserEmailTemplate, templateData);

      var emailModel = {
        subject: "Account Verification",
        html: emailTemplate,
        to: [
          {
            address: createdUser.email,
            displayName: createdUser.firstName + " " + createdUser.lastName,
          },
        ],
      };

      await _sharedService.sendEmail(emailModel);

      messageApi.open({
        type: "success",
        content: "User created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      onCloseUserCreateModal();
      
      //REDIRECT USER TO PROFILE PAGE
      navigate("/profile/edit", {
        state: { userData: createdUser, defaultTab: "2" },
      });
      
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: error.message ? error.message : "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      onCloseUserCreateModal();
      setIsLoading(false);

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  };

  const handleCreateUserInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handlePhoneNumberInputChange = (value) => {
    user.mobileNumber = value;
  };
  return (
    <>
      <UnauthenticatedTemplate>
        <Menu
          onClick={onMenuItemClick}
          mode="inline"
          defaultSelectedKeys={[]}
          className="menu-bar"
          items={MenuItems}
        />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {contextHolder}
        <Spin spinning={isLoading} fullscreen size="large" />
        <CreateUser
          isLoading={isLoading}
          onOpenCreateUserModal={createUserModal}
          onCancelCreateUserModal={onCloseUserCreateModal}
          onCreateUser={onCreateUser}
          onInputChange={handleCreateUserInputChange}
          onPhoneNumberChange={handlePhoneNumberInputChange}
        />
        <Menu
          onClick={onMenuItemClick}
          mode="inline"
          defaultSelectedKeys={[]}
          className="menu-bar"
          items={loggedUser && loggedUser.role && loggedUser.role == "admin" ? AdminMenuItems : MenuItems}
        />
      </AuthenticatedTemplate>
    </>
  );
}

export default Sidebar;
