const config = require("../config");

class SharedServices {
  constructor(httpClient) {
    this.BASE_URL = config.BASE_URL;
    this.httpClient = httpClient;
  }

  async sendEmail(emailModel) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/SendEmail",
        emailModel
      );
      
      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }
      
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default SharedServices;
