import { Card, Tabs, Modal, Flex, Button } from "antd";
import React, { useState, useEffect } from "react";
import EventInformation from "./AddEventTabs/EventInformation";
import Facilitator from "./AddEventTabs/Facilitator";
import Schedule from "./AddEventTabs/Schedule";
import Participants from "./AddEventTabs/Participants";
import Notes from "./AddEventTabs/Notes";
import EventStatus from "./AddEventTabs/EventStatus";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Header } from "antd/es/layout/layout";
import CustomHeader from "../../components/Header";

import { ColorRing } from "react-loader-spinner";
import { ExclamationCircleFilled } from "@ant-design/icons";

function ProfileTabs() {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPreRecorded, setIsPreRecorded] = useState(false);
  const [tabsDisabled, setTabsDisabledDisabled] = useState(true);
  const [eventData, setEventData] = useState({});
  const [defaultTab, setDefaultTab] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  let activeAccount;
  let loggedUser;

  if (instance) {
    activeAccount = instance.getActiveAccount();
    loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  }

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.role === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    
    if(location?.state?.event) {
      setEventData(location.state.event);
      setTabsDisabledDisabled(false);
    } else {
      setTabsDisabledDisabled(true);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsPreRecorded(
      eventData && Object.keys(eventData).length > 0 && (eventData.classType === "Pre-recorded DVD" || eventData.classType === "Pre-recorded On Demand")
    )
  }, [eventData]);

  //HANDLE TAB CHANGE
  const onChange = (key) => {
    if (!isSaveDisabled) {
      showConfirm(key);
      return;
    } else {
      setActiveTab(key);
    }
  };

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to proceed without saving changes?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        setIsSaveDisabled(true);
        setActiveTab(key);
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };
  //TABS
  const items = [
    {
      key: 1,
      label: "General Information",
      children: (
        <EventInformation
          setActiveTab={setActiveTab}
          eventData={eventData}
          setEventData={setEventData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
          setTabsDisabledDisabled={setTabsDisabledDisabled}
        />
      ),
    },
    {
      key: 2,
      label: "Facilitator",
      disabled: tabsDisabled,
      children: (
        <Facilitator
          setActiveTab={setActiveTab}
          eventData={eventData}
          setEventData={setEventData}
          isSaveDisabled={isSaveDisabled}
          isPreRecorded={isPreRecorded}
          setIsSaveDisabled={setIsSaveDisabled}
        />
      ),
    },
    {
      key: 3,
      label: "Schedule",
      disabled: tabsDisabled || isPreRecorded,
      children: (
        <Schedule
          setActiveTab={setActiveTab}
          eventData={eventData}
          setEventData={setEventData}
        />
      ),
    },
    {
      key: 4,
      label: "Participants",
      disabled: tabsDisabled,
      children: (
        <Participants
          setActiveTab={setActiveTab}
          eventData={eventData}
          isPreRecorded={isPreRecorded}
          setEventData={setEventData}
        />
      ),
    },
    {
      key: 5,
      label: "Notes",
      disabled: tabsDisabled,
      children: (
        <Notes
          setActiveTab={setActiveTab}
          eventData={eventData}
          setEventData={setEventData}
        />
      ),
    },
    {
      key: 6,
      label: "Status",
      disabled: tabsDisabled,
      children: (
        <EventStatus
          setActiveTab={setActiveTab}
          eventData={eventData}
          setEventData={setEventData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
        />
      ),
    },
  ];

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (!isAdmin) {
    return (
      <Card>
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              <span style={{fontSize: 16, fontWeight: 'bold'}}>
                You are not authorized to access this page.
              </span>
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
      <CustomHeader
        items={[{ title: "Classes & Events", href: "/classesAndEventsList" }]}
      />
      </Header>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={activeTab}
        defaultActiveKey={defaultTab}
        destroyInactiveTabPane={true}
      ></Tabs>
    </>
  );
}

export default ProfileTabs;
