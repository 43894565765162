import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  message,
  Select,
} from "antd";
import EventService from "../../../services/EventService";
import UserService from "../../../services/UserService";
import FacilitatorService from "../../../services/FacilitatorService";
import FetchClient from "../../../serviceClients/FetchClient";
import CreateFacilitator from "../../../components/ClassesEvents/CreateFacilitator";
const { TextArea } = Input;

function Facilitator({ setActiveTab, eventData, setEventData, isPreRecorded, isSaveDisabled, setIsSaveDisabled }) {
  const location = useLocation()
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [facilitators, setFacilitators] = useState([]);
  const [teamFacilitators, setTeamFacilitators] = useState([]);
  const [error, setError] = useState({});
  const [temporalFacilitator, setTemporalFacilitator] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(location.state?.event);
  const [isLoading, setIsLoading] = useState(false);
  const [createFacilitatorModal, setCreateFacilitatorModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    fetchEvent();
  }, []);

  async function fetchUsers() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const userService = new UserService(_fetchInstance);

    try {
      const userData = await userService.getUsers();
      setIsLoading(false);
      return userData.map((user) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  }
  async function fetchFacilitators() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const facilitatorService = new FacilitatorService(_fetchInstance);

    try {
      const facilitatorData = await facilitatorService.getAllFacilitators();
      return facilitatorData.map((facilitator) => {
          return {
            label: facilitator.name,
            value: facilitator.id,
          };
        });
    } catch (error) {
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchEvent() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const eventService = new EventService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const eventData = await eventService.getEvent(eventId);
      fillEventValues(eventData);
    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  const fillEventValues = async (event) => {
    let userFacilitators = [], localTeamFacilitators = [];
    let newFacilitators = [];

    if (event && eventData.facilitators && eventData.facilitators.length > 0) {
      event.facilitators.forEach(facilitator => {
        newFacilitators.push({value: facilitator.id, label: `${facilitator.firstName} ${facilitator.lastName}`});
        userFacilitators.push(facilitator.id);

      });
      setFacilitators(userFacilitators);
    }

    if (event && eventData.teamFacilitators && eventData.teamFacilitators.length > 0) {
      event.teamFacilitators.forEach(facilitator => {
        newFacilitators.push({value: facilitator.id, label: facilitator.name});
        localTeamFacilitators.push(facilitator.id);
      });
      setTeamFacilitators(localTeamFacilitators);
    }

    setEventData(event);
    form.setFieldsValue({ 
      facilitators: newFacilitators,
      contactName: event.contactName,
      contactPhone: event.contactPhone,
      contactEmail: event.contactEmail,
      contactAdditionalInfo: event.contactAdditionalInfo,
      organizer: event.organizer,
      assistant: event.assistant,
      sponsor: event.sponsor,
    });
    setIsSaveDisabled(true);
  };

  const setButtonDisabled = () => {
    const factilitatorValues = form.getFieldsValue();
    if (factilitatorValues.facilitators?.length && factilitatorValues.contactName && factilitatorValues.contactPhone && 
      factilitatorValues.contactEmail && factilitatorValues.organizer) {
      setIsSaveDisabled(false);
    } 
    else 
      setIsSaveDisabled(true);
  };

  const handleFormChange = () => {
    setButtonDisabled();
  };

  const deselectFacilitator = (currentValue) => {
    if (facilitators.find((facilitator) => facilitator == currentValue)) {
      setFacilitators(facilitators.filter((facilitator) => currentValue != facilitator));
    }
    else
      setTeamFacilitators(teamFacilitators.filter((facilitator) => currentValue != facilitator));
  };

  const onCreateFacilitator = () => {
    const formFacilitators = form.getFieldValue("facilitators") || [];
    const newFacilitators = [...formFacilitators, { value: temporalFacilitator.value, label: temporalFacilitator.label }];
    form.setFieldsValue({facilitators: newFacilitators});
    if (temporalFacilitator.type == "team") {
      setTeamFacilitators([...teamFacilitators, temporalFacilitator.value]);
    } else {
      setFacilitators([...facilitators, temporalFacilitator.value]);
    }
    setCreateFacilitatorModal(false);
    handleFormChange();
  };

  const onCloseFacilitatorCreateModal = () => {
    setCreateFacilitatorModal(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    //Call Service to update user data
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _eventService = new EventService(_fetchClient);

    let eventId = localStorage.getItem("eventId");
    const updatedEvent = {
      ...values,
      teamFacilitators: teamFacilitators,
      facilitators: facilitators,
      id: eventId,
    };
    try {
      let newEventData = { 
        ...eventData,
        ...updatedEvent
      };

      let res = await _eventService.updateEvent(updatedEvent);
      setEventData(newEventData);
      messageApi.open({
        type: "success",
        content: "User profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      setIsLoading(false);
      // navigate(".", { state: { eventData: newEventData } });
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsSaveDisabled(true);
      setIsLoading(false);
      setActiveTab(isPreRecorded ? 4 : 3)
    }
  };

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="facilitatorsForm"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col span={11}>
            <Form.Item
              label="Facilitators"
              name="facilitators"
              rules={[
                { required: true, message: "This field is required" },
              ]}
            >
              <Select
                mode="multiple"
                onDeselect={deselectFacilitator}
                onChange={handleFormChange}
                onClear={handleFormChange}
                showSearch={false}
                dropdownStyle={{ display: "none" }}
                suffixIcon={null}
              />
            </Form.Item>
          </Col>
          <Col span={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setCreateFacilitatorModal(true)}
              style={{ marginTop: 30 }}
            >
              +
            </Button>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Contact Name"
              name="contactName"
              rules={[
                { required: true, message: "This field is required" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Phone"
              name="contactPhone"
              rules={[
                { required: true, message: "This field is required" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="contactEmail"
              rules={[
                { required: true, message: "This field is required" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Additional Contact Information"
              name="contactAdditionalInfo"
            >
              <TextArea
                style={{ height: "100px" }}
                name="contactAdditionalInfo"
                placeholder="Additional contact information"
                onChange={handleFormChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Organizer"
              name="organizer"
              rules={[
                { required: true, message: "This field is required" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assistant"
              name="assistant"
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Sponsor"
              name="sponsor"
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <div style={{ textAlign: "left" }}>
              <Button size="middle" onClick={() => setActiveTab(1)} className="confirmButton">
                Back
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button size="middle" htmlType="submit" className="confirmButton" disabled={isSaveDisabled}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <CreateFacilitator
        onOpenCreateFacilitatorModal={createFacilitatorModal}
        onCancelCreateFacilitatorModal={onCloseFacilitatorCreateModal}
        onCreateFacilitator={onCreateFacilitator}
        setTemporalFacilitator={setTemporalFacilitator}
      />
    </Card>
  );
}

export default Facilitator;
