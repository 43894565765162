import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Card, Flex, Space, Table, Button, DatePicker, message } from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import FetchClient from "../../serviceClients/FetchClient";
import AddFamilyMember from "./AddFamilyMember";
import UserService from "../../services/UserService";

function MembersList({ members, user, isSameAdminUser, familyMembersEnabled, onFinishMembersList }) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [onOpenCreateFamilyMemberModal, setOnOpenCreateFamilyMemberModal] =
    useState(false);
  const [familyMembers, setFamilyMembers] = useState(
    members && members.length > 0 ? members : []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [familyMemberToUpdate, setFamilyMemberToUpdate] = useState(null);
  const [error, setError] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showTotal: (total, range) =>
        `Viewing ${range[0]}-${range[1]} of ${total} results`,
      pageSizeOptions: [10, 20, 50, 100],
    },
  });
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  function showDeleteModal() {
    showConfirm();
  }
  async function deleteFamilyMember() {
    const newFamilyMembers = familyMembers.filter(
      (member) =>
        !selectedFamilyMembers.some((selected) => selected.id == member.id)
    );
    try {
      await updateFamilyMembers(user, newFamilyMembers);
      await deleteFamilyMemberFamilyMembers(selectedFamilyMembers[0].id);
      onFinishMembersList({ ...user, familyMembers: newFamilyMembers })
      setFamilyMembers(newFamilyMembers);
      messageApi.open({
        type: "success",
        content: "Family Members were deleted.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      setError("Couldn't delete family member");
      messageApi.open({
        type: "success",
        content: "Couldn't delete family member!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }
  const deleteFamilyMemberFamilyMembers = async (id) => {
    const familyMember = await getUser(id);
    let newMembers = [];
    if (familyMember.familyMembers && familyMember.familyMembers.length > 0)
      newMembers = [...familyMember.familyMembers];
    newMembers = newMembers.filter(
      (member) => member.id != user.id
    )
    try {
      await updateFamilyMembers(familyMember, newMembers);
    } catch (error) {
      console.log(error);
    }
  }

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setFamilyMembers([]);
    }
  };

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = async (record) => {

    setFamilyMemberToUpdate({ ...record, user: record.id });
    setOnOpenCreateFamilyMemberModal(true);
  };

  const handleNameClick = async (record) => {
    navigate("/profile/edit", {
      state: {
        userData: record,
      },
    });
  };

  //SET EVENT LIST TABLE COLUMNS
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: 'ascend',
      render: (_, record) => <div onClick={() => handleNameClick(record)}>{record.name}</div>,
    },
    {
      title: "Family Member Is",
      dataIndex: "familyMemberIs",
      key: "familyMemberIs",
    },
    {
      title: "Current User Is",
      dataIndex: "currentUserIs",
      key: "currentUserIs",
    },
    {
      title: "Family Plan",
      dataIndex: "familyPlan",
      key: "familyPlan",
      render: (_, record) => (
        <span>
          {record.familyPlan && record.familyPlan == "1" ? "Yes" : "No"}
        </span>
      ),
    },
    {
      title: "Deceased",
      dataIndex: "deceased",
      key: "deceased",
      render: (_, record) => (
        <span>{record.deceased && record.deceased == "1" ? "Yes" : "No"}</span>
      ),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you would like to delete these Family Members?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteFamilyMember();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCancelCreateFamilyMemberModal = () => {
    setFamilyMemberToUpdate(null);
    setOnOpenCreateFamilyMemberModal(false);
  };

  const onCreateFamilyMember = async (values) => {
    setIsLoading(true);
    try {
      await createUsersFamilyMembers(values);
      await createFamilyMemberFamilyMembers(values);
      messageApi.open({
        type: "success",
        content: "Family member created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      setError("Couldn't add family member");
      messageApi.open({
        type: "success",
        content: "Couldn't add family member",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
      setOnOpenCreateFamilyMemberModal(false);
    }
  };

  const createUsersFamilyMembers = async (values) => {
    let newMembers = [];
    if (familyMembers && familyMembers.length > 0)
      newMembers = [...familyMembers];
    newMembers.push({
      name: values.user,
      id: values.familyMemberId,
      familyMemberIs: values.familyMemberIs,
      currentUserIs: values.currentUserIs,
      familyPlan: values.familyPlan,
      deceased: "0",
    });
    try {
      await updateFamilyMembers(user, newMembers);
      onFinishMembersList({ ...user, familyMembers: newMembers });
      setFamilyMembers(newMembers);
    } catch (error) {
      console.log(error);
    } 
  }

  const updateUsersFamilyMembers = async (member) => {
    let newMembers = [];
    if (familyMembers && familyMembers.length > 0)
      newMembers = familyMembers.filter(
        (familyMember) => familyMember.id != member.id
      );
    newMembers.push(member);
    try {
      await updateFamilyMembers(user, newMembers);
      onFinishMembersList({ ...user, familyMembers: newMembers });
      setFamilyMembers(newMembers);
    } catch (error) {
      setError("Couldn't add family member");
    }
  }

  const createFamilyMemberFamilyMembers = async (values) => {
    const familyMember = await getUser(values.familyMemberId);
    let newMembers = [];
    if (familyMember.familyMembers && familyMember.familyMembers.length > 0)
      newMembers = [...familyMember.familyMembers];
    newMembers.push({
      name: user.firstName + " " + user.lastName,
      id: user.id,
      familyMemberIs: values.currentUserIs,
      currentUserIs: values.familyMemberIs,
      familyPlan: values.familyPlan,
      deceased: "0",
    });
    try {
      await updateFamilyMembers(familyMember, newMembers);
    } catch (error) {
      console.log(error);
    }
  }

  const updateFamilyMemberFamilyMembers = async (values) => {
    const familyMember = await getUser(values.id);
    let newMembers = [];
    if (familyMember.familyMembers && familyMember.familyMembers.length > 0)
      newMembers = familyMember.familyMembers.filter(
        (familyMember) => familyMember.id != user.id
      );
    newMembers.push({
      name: user.firstName + " " + user.lastName,
      id: user.id,
      familyMemberIs: values.currentUserIs,
      currentUserIs: values.familyMemberIs,
      familyPlan: values.familyPlan,
      deceased: "0",
    });
    try {
      await updateFamilyMembers(familyMember, newMembers);
    } catch (error) {
      console.log(error);
    }
  }

  const getUser = async (id) => {
    try {
      const _fetchInstance = new FetchClient(activeAccount.idToken);
      const _userService = new UserService(_fetchInstance);
      const user = await _userService.getUserById(id);
      return user;
    } catch(error){
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
      return null;
    }
  }

  const onEditFamilyMember = async (member) => {
    setIsLoading(true);
    try {
      await updateUsersFamilyMembers(member);
      await updateFamilyMemberFamilyMembers(member);
      messageApi.open({
        type: "success",
        content: "Family Member changes have been saved.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      setError("Couldn't update family member");
      messageApi.open({
        type: "success",
        content: "Couldn't update family member",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
      setOnOpenCreateFamilyMemberModal(false);
    }
  };

  async function updateFamilyMembers(userToUpdate, members) {
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _userService = new UserService(_fetchInstance);

    try {
      await _userService.updateUser({
        ...userToUpdate,
        familyMembers: members,
      });
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      } 
    }
  }

  //HANDLE COURSE LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedFamilyMembers(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Course",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <AddFamilyMember
        onOpenCreateFamilyMemberModal={onOpenCreateFamilyMemberModal}
        onCancelCreateFamilyMemberModal={onCancelCreateFamilyMemberModal}
        onCreateFamilyMember={onCreateFamilyMember}
        familyMember={familyMemberToUpdate}
        user={user}
        setFamilyMemberToUpdate={setFamilyMemberToUpdate}
        onEditFamilyMember={onEditFamilyMember}
      />
      <span style={{display: 'block', marginTop: '2%', fontWeight: 'bold' }}>Family Members</span>
      <div
        style={{ width: "100%", border: "0.5px solid", marginBottom: "30px" }}
      />
      <Flex vertical gap="30px">
        <Flex vertical align="flex-start">
          <div className="course-table">
            <Space
              direction="vertical"
              size="small"
              style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
            >
              <Space size="small" align="center">
                {!isSameAdminUser && (
                  <Button
                    onClick={() => setOnOpenCreateFamilyMemberModal(true)}
                    className="confirmButton"
                    style={{ display: familyMembersEnabled ? "block": "none", marginBottom: "8px" }}
                    >
                    Add Family Member +
                  </Button>
                )}
                {selectedFamilyMembers.length === 1 && (
                  <Button
                    onClick={showDeleteModal}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                  >
                    Delete
                  </Button>
                )}
              </Space>
            </Space>
            <Table
              rowClassName={"tableRowActiveCourse"}
              rowSelection={!isSameAdminUser ? {type: "checkbox", ...rowSelection} : null}
              columns={columns}
              dataSource={familyMembers}
              rowKey={(record) => record.id}
              pagination={false}
              onChange={handleTableChange}
              // scroll={{ y: 55 * 5 }}
            />
          </div>
        </Flex>
      </Flex>
    </>
  );
}
export default MembersList;
