import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import dayjs from "dayjs";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ScheduleService from "../../../services/ScheduleService";
import FetchClient from "../../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Input,
  Space,
  Table,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import CreateSchedule from "../../../components/ClassesEvents/CreateSchedule";

function Schedule({ setActiveTab }) {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [schedule, setSchedule] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [allSchedules, setAllSchedules] = useState([]);
  const [editSchedule, setEditSchedule] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createScheduleeModal, setCreateScheduleModal] = useState(false);
  const [error, setError] = useState();
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  async function fetchSchedules() {
    // setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const scheduleService = new ScheduleService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const scheduleData = await scheduleService.getAllSchedules(eventId);
      setSchedules(scheduleData);
      setAllSchedules(scheduleData);
    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  async function deleteSchedule() {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _scheduleService = new ScheduleService(_fetchInstance);
    
    try {
      //DELETE USER
      for (const schedule of selectedSchedules) {
        await _scheduleService.deleteSchedule(schedule);
      }
      messageApi.open({
        type: "success",
        content: "Schedule item has been deleted.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setSelectedSchedules([]);
      setIsLoading(false);
      fetchSchedules();
    }
  }

  useEffect(() => {
    fetchSchedules();
  }, [
    activeAccount.idToken,
  ]);

  const handleEditClick = (record) => {
    setEditSchedule(record);
    setCreateScheduleModal(true);
  };

  const columns = [
    {
      title: "Schedule Item",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: (a, b) => a.item.localeCompare(b.item),
      render: (_, record) => (
        <>
          <span>{dayjs(record.startDate).utc().format('MM/DD/YYYY')}</span>
        </>
      ),
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      sorter: (a, b) => a.item.localeCompare(b.item),
      render: (_, record) => (
        <>
          <span>{dayjs(record.startDate).utc().format('h:mm:ss A')}</span>
        </>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      sorter: (a, b) => a.item.localeCompare(b.item),
      render: (_, record) => (
        <>
          <span>{dayjs(record.endDate).utc().format('MM/DD/YYYY')}</span>
        </>
      ),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      sorter: (a, b) => a.item.localeCompare(b.item),
      render: (_, record) => (
        <>
          <span>{dayjs(record.endDate).utc().format('h:mm:ss A')}</span>
        </>
      ),
    },
    {
      title: "Presenter",
      dataIndex: "presenterName",
      key: "presenterName",
      sorter: (a, b) => a.presenterName.localeCompare(b.presenterName),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this schedule?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteSchedule();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCloseScheduleCreateModal = () => {
    setEditSchedule(null);
    setSchedule(null);
    setCreateScheduleModal(false);
  };

  const onCreateSchedule = async () => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _scheduleService = new ScheduleService(_fetchInstance);
    let createdSchedule = {};

    try {
      //CREATE USER
      let eventId = localStorage.getItem("eventId");
      createdSchedule = await _scheduleService.createSchedule({
        ...schedule,
        eventId: eventId,
      });
      messageApi.open({
        type: "success",
        content: "Schedule created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      onCloseScheduleCreateModal();
      setIsLoading(false);
      fetchSchedules();
    }
  };

  const onEditSchedule = async () => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _scheduleService = new ScheduleService(_fetchInstance);
    let createdSchedule = {};

    try {
      createdSchedule = await _scheduleService.updateSchedule(schedule);
      messageApi.open({
        type: "success",
        content: "Schedule updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      onCloseScheduleCreateModal();
      setIsLoading(false);
      fetchSchedules();
    }
  };

  const handleCreateScheduleInputChange = (e) => {
    const { name, value } = e.target;
    setSchedule({ ...schedule, [name]: value });
  };

  const handleCreateScheduleStartDateInputChange = (e) => {
    setSchedule({
      ...schedule,
      startDate: e ? dayjs(e).utc('z').toISOString() : null
    });
  };
  

  const handleCreateScheduleEndDateInputChange = (e) => {
    setSchedule({
      ...schedule,
      endDate: e ? dayjs(e).utc('z').toISOString() : null
    });
  };

  const rowSelection = {
    hideSelectAll : true,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSchedules(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Schedule",
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                direction="vertical"
                size="small"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  onClick={() => setCreateScheduleModal(true)}
                  className="confirmButton"
                  style={{ marginBottom: "8px" }}
                  >
                  Add Schedule Item +
                </Button>
                {selectedSchedules.length > 0 && (
                  <Button
                    onClick={showDeleteModal}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                  >
                    Delete
                  </Button>
                )}
              </Space>
              <Table
                rowClassName={"tableRowActiveSchedule"}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={schedules}
                rowKey={(record) => record.id}
                pagination={false}
              />
              <CreateSchedule
                onOpenCreateScheduleModal={createScheduleeModal}
                onCancelCreateScheduleModal={onCloseScheduleCreateModal}
                onEditSchedule={onEditSchedule}
                onCreateSchedule={onCreateSchedule}
                onInputChange={handleCreateScheduleInputChange}
                onStartDateInputChange={handleCreateScheduleStartDateInputChange}
                onEndDateInputChange={handleCreateScheduleEndDateInputChange}
                setSchedule={setSchedule}
                schedule={schedule}
                allSchedules={schedules}
                editSchedule={editSchedule}
              />
            </div>
          </Flex>
        </Flex>
        <Row style={{ marginTop: "20px" }} gutter={24}>
          <Col span={12}>
            <div style={{ textAlign: "left" }}>
              <Button onClick={() => setActiveTab(2)} className="confirmButton">
                Back
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => setActiveTab(4)}
                htmlType="submit"
                className="confirmButton"
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default Schedule;
