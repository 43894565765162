import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import { ExclamationCircleFilled } from "@ant-design/icons";
import UserService from "../../services/UserService";
import NoteService from "../../services/NoteService";
import FetchClient from "../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Input,
  Space,
  Table,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import CreateNote from "../../components/ClassesEvents/CreateNote";

function Notes({ userData }) {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [note, setNote] = useState({});
  const [notes, setNotes] = useState([]);
  const [allNotes, setAllNotes] = useState(userData?.notes);
  const [editNote, setEditNote] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createNoteModal, setCreateNoteModal] = useState(false);
  const [error, setError] = useState();
  const [nameSearchText, setNameSearchText] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      pageSizeOptions: [10, 20, 50, 100],
    },
  });
  const [selectedNotes, setSelectedNotes] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  
  async function fetchNotes() {
    setIsLoading(true);
    try {
      setNotes(userData?.notes ?? []);
      setAllNotes(userData?.notes ?? []);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination },
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  async function deleteNote() {
    setIsLoading(true);
    setSelectedNotes([]);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);
      //DELETE USER
    let newNotesArray = notes.filter((note) => {
      return !selectedNotes.some((selectedNote) => selectedNote.id == note.id);
    })
    updateUserNotes(newNotesArray);
  }

  useEffect(() => {
    fetchNotes();
  }, [
    activeAccount.idToken,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setNotes([]);
    }
  };

  const handleEditClick = (record) => {
    setEditNote(record);
    setCreateNoteModal(true);
  };

  const columns = [
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.text?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.text.localeCompare(b.text),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.createdBy?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: "Date Created",
      dataIndex: "creationDate",
      key: "creationDate",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.creationDate?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.creationDate.localeCompare(b.creationDate),
      render: (_, record) => (
        <>
          <span>{dayjs(record.creationDate).format('MM/DD/YYYY')}</span>
        </>
      ),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this note?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteNote();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCloseNoteCreateModal = () => {
    setEditNote({});
    setNote({});
    setCreateNoteModal(false);
  };

  const onCreateNote = async () => {
    setIsLoading(true);
    setEditNote({});
    setNote({});
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    let newNote = {
      ...note,
      id: uuidv4(),
      creationDate: dayjs().toISOString(),
      createdBy: `${loggedUser.firstName} ${loggedUser.lastName}`,
    }
    let newNotesArray = [...notes, newNote];
    await updateUserNotes(newNotesArray);
    onCloseNoteCreateModal();
  };


  const updateUserNotes = async (newNotesArray) => {
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _userService = new UserService(_fetchInstance);
    let createdNote = {};
    try {
      createdNote = await _userService.updateUser({
        ...userData,
        notes: newNotesArray
      });
      messageApi.open({
        type: "success",
        content: "Note created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setAllNotes(newNotesArray);
      setNotes(newNotesArray);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      } else {
        setAllNotes(newNotesArray);
        setNotes(newNotesArray);
        setIsLoading(false);
      }
    }
  }

  const onEditNote = async (values) => {

    setIsLoading(true);
    let newNote = {...editNote, ...values};
    let newNotesArray = notes.map((originalNote) => (originalNote.id === editNote.id ? newNote : originalNote))
    setNotes(newNotesArray);
    setAllNotes(newNotesArray);
    await updateUserNotes(newNotesArray);
    onCloseNoteCreateModal();
  };

  const handleCreateNoteInputChange = (e) => {
    const { name, value } = e.target;
    setNote({ ...note, [name]: value });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedNotes(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Note",
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      {contextHolder}
      <Flex vertical gap="30px">
        <Flex vertical align="flex-start">
          <div className="course-table">
            <Space
              direction="vertical"
              size="small"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              {selectedNotes.length >= 1 && (
                <Button
                  onClick={showDeleteModal}
                  className="confirmButton"
                  style={{ marginBottom: "8px" }}
                >
                  Delete
                </Button>
              )}
              <Button
                onClick={() => setCreateNoteModal(true)}
                className="confirmButton"
                style={{ marginBottom: "8px" }}
              >
                Add Note +
              </Button>
            </Space>
            <Table
              rowClassName={"tableRowActiveNote"}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={columns}
              dataSource={notes}
              rowKey={(record) => record.id}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
            <CreateNote
              onOpenCreateNoteModal={createNoteModal}
              onCancelCreateNoteModal={onCloseNoteCreateModal}
              onEditNote={onEditNote}
              onCreateNote={onCreateNote}
              onInputChange={handleCreateNoteInputChange}
              setNote={setNote}
              note={note}
              allNotes={notes}
              editNote={editNote}
            />
          </div>
        </Flex>
      </Flex>
    </>
  );
}
export default Notes;
