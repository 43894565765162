import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { ExclamationCircleFilled } from "@ant-design/icons";
import EventService from "../../services/EventService";
import FetchClient from "../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Input,
  Select,
  Space,
  Table,
  Button,
  DatePicker,
  message,
} from "antd";
import NoteShow from "../ClassesEvents/NoteShow";
import { Header } from "antd/es/layout/layout";
import CustomHeader from "../../components/Header";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

function ClassList() {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [onOpenShowNoteModal, setOnOpenShowNoteModal] = useState(false);
  const [note, setNote] = useState("");
  const [eventNameSearchText, setEventNameSearchText] = useState("");
  const [courseCodeSearchText, setCourseCodeSearchText] = useState("");
  const [eventTypeSearchText, setEventTypeSearchText] = useState("");
  const [creditSearchText, setCreditSearchText] = useState("");
  const [paidSearchText, setPaidSearchText] = useState("");
  const [selectedEventTypeValues, setSelectedEventTypeValues] = useState(null);
  const [selectedCreditValues, setSelectedCreditValues] = useState(null);
  const [selectedPaidValues, setSelectedPaidValues] = useState(null);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      pageSizeOptions: [10, 20, 50, 100],
    },
  });
  
  const [selectedEvents, setSelectedEvents] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const onCancelShowNoteModal = () => {
    setNote("");
    setOnOpenShowNoteModal(false);
  };

  const openNoteModal = (note) => {
    setNote(note);
    setOnOpenShowNoteModal(true);
  };

  function handleEventTypeSearchChange(value) {
    setSelectedEventTypeValues(value);
    setEventTypeSearchText(value);
  }

  function handleCreditSearchChange(value) {
    setSelectedCreditValues(value);
    setCreditSearchText(value);
  }

  function handlePaidSearchChange(value) {
    setSelectedPaidValues(value);
    setPaidSearchText(value);
  }

  function getEventDate(event) {
    return `${moment(
      new Date(
        Math.min.apply(
          null,
          event.schedules.map((schedule) => new Date(schedule.startDate))
        )
      )
    ).format("L")} - ${moment(
      new Date(
        Math.max.apply(
          null,
          event.schedules.map((schedule) => new Date(schedule.endDate))
        )
      )
    ).format("L")}`;
  }

  //GET COURSE LIST
  async function fetchEvents() {
    setIsLoading(true);
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    const currentUser = {
      ...loggedUser,
      email: activeAccount.username,
    };
    const currentUserData = location.state?.userData || currentUser;
    setUserData(currentUserData);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const eventService = new EventService(_fetchInstance);

    try {
      const eventData = await eventService.getAllEventsById(currentUserData.id);
      console.log("getAllEventsById eventData: ", eventData);
      setEvents(
        eventData.map((event) => {
          if (event.schedules && event.schedules.length > 0) {
            return {
              ...event,
              eventDate: getEventDate(event),
            };
          } else {
            return {
              ...event,
              eventDate: "",
            };
          }
        })
      );
      setAllEvents(eventData);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination },
      });
    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  const handleClassClick = async (record) => {
    await localStorage.setItem("eventId", record.id);
    navigate("/addEvent", {
      state: {
        event: record,
      },
    });
  };

  function clearFilters() {
    setEventNameSearchText("");
    setCourseCodeSearchText("");
    setEventTypeSearchText("");
    setCreditSearchText("");
    setPaidSearchText("");
    setSelectedEventTypeValues(null);
    setSelectedCreditValues(null);
    setSelectedPaidValues(null);
  }

  async function deleteCourse() {
    setIsLoading(true);
    setSelectedEvents([]);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _courseService = new EventService(_fetchInstance);

    try {
      //DELETE USER
      await _courseService.deleteCourse(selectedEvents[0]);
      messageApi.open({
        type: "success",
        content: "Course deleted successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
      fetchEvents();
    }
  }

  useEffect(() => {
    fetchEvents();
  }, [
    activeAccount.idToken,
    // tableParams.pagination?.current,
    // tableParams.pagination?.pageSize,
  ]);

  const getClassTypeLabel = (classType) => {
    switch (classType) {
      case "Pre-recorded DVD":
        return "Pre-recorded DVD";
      case "Pre-recorded On Demand":
        return "Pre-recorded On Demand";
      case "live":
        return "Live Streamed";
      case "person":
        return "In Person";
      case "hybrid":
        return "Hybrid";
      default:
        return "";
    }
  }

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setEvents([]);
    // }
  };

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = async (record) => {
    await localStorage.setItem("eventId", record.id);
    navigate("/addEvent", {
      state: {
        event: record,
      },
    });
  };

  //SET EVENT LIST TABLE COLUMNS
  const columns = [
    {
      title: "Name",
      dataIndex: "eventName",
      key: "eventName",
      filteredValue: [eventNameSearchText],
      onFilter: (value, record) => {
        return record.eventName?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.eventName.localeCompare(b.eventName),
      render: (_, record) => <div style={{ cursor: "pointer" }} onClick={() => handleClassClick(record)}>{record.eventName}</div>,
    },
    {
      title: "CTI ID",
      dataIndex: "CTIId",
      key: "CTIId",
      filteredValue: [eventNameSearchText],
      onFilter: (value, record) => {
        return record.CTIId?.toLowerCase().includes(value.toLowerCase());
      },
      render: (_, record) => <>{record.CTIId}</>,
    },
    {
      title: "Type",
      dataIndex: "eventType",
      key: "eventType",
      filteredValue: [eventTypeSearchText],
      onFilter: (value, record) => {
        return value == undefined || value == "undefined"
          ? true
          : record.eventType?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.eventType.localeCompare(b.eventType),
    },
    {
      title: "Course Code",
      dataIndex: "course",
      key: "course",
      filteredValue: [courseCodeSearchText],

      onFilter: (value, record) => {
        if (value && value != "") {
          return !value || value == ""
            ? true
            : record.code?.toLowerCase().includes(value.toLowerCase());
        } else {
          return true;
        }
      },
      sorter: (a, b) => a.code?.localeCompare(b.code),
      render: (_, record) => <>{record.eventType === "Event" ? "NC" : record.code}</>,
    },
    {
      title: "Class Type",
      dataIndex: "classType",
      key: "classType",
      filteredValue: [eventNameSearchText],
      onFilter: (value, record) => {
        return value == undefined || value == "undefined"
          ? true
          : record.classType?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.classType.localeCompare(b.classType),
      render: (_, record) => <>{record.classType}</>,
    },
    {
      title: "Event Start Date",
      dataIndex: "eventStartDate",
      key: "eventStartDate",
      // filteredValue: [eventNameSearchText],
      sorter: (a, b) => a.eventStartDate.localeCompare(b.eventStartDate),
      render: (_, record) => <>{dayjs(record.eventStartDate).utc().format('MM/DD/YYYY')}</>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      // filteredValue: [paidSearchText],
      // onFilter: (value, record) => {
      //   return value == undefined || value == "undefined" || value == ""
      //     ? true
      //     : record.participant?.role == value;
      // },
      sorter: (a, b) => a.participant.role.localeCompare(b.participant.role),
      render: (_, record) => (
        <>
          {record.participant?.role}
        </>
      ),
    },
    {
      title: "Facilitators",
      dataIndex: "facilitators",
      key: "facilitators",
      filteredValue: [eventNameSearchText],
      sorter: (a, b) => `${
            a.facilitators && a.facilitators.length > 0
              ? a.facilitators
                  .map(
                    (facilitator) =>
                      `${facilitator.firstName} ${facilitator.lastName}`
                  )
                  .join(", ")
              : ""
          }${
            b.teamFacilitators && b.teamFacilitators.length > 0
              ? ", " +
                b.teamFacilitators
                  .map((facilitator) => facilitator.name)
                  .join(", ")
              : ""
          }`.localeCompare(`${
            b.facilitators && b.facilitators.length > 0
              ? b.facilitators
                  .map(
                    (facilitator) =>
                      `${facilitator.firstName} ${facilitator.lastName}`
                  )
                  .join(", ")
              : ""
          }${
            b.teamFacilitators && b.teamFacilitators.length > 0
              ? ", " +
                b.teamFacilitators
                  .map((facilitator) => facilitator.name)
                  .join(", ")
              : ""
          }`),
      onFilter: (value, record) => {
        return value == undefined || value == "undefined"
          ? true
          : `${
            record.facilitators && record.facilitators.length > 0
              ? record.facilitators
                  .map(
                    (facilitator) =>
                      `${facilitator.firstName} ${facilitator.lastName}`
                  )
                  .join(", ")
              : ""
          }${
            record.teamFacilitators && record.teamFacilitators.length > 0
              ? ", " +
                record.teamFacilitators
                  .map((facilitator) => facilitator.name)
                  .join(", ")
              : ""
          }`.toLowerCase().includes(value.toLowerCase());
      },
      render: (_, record) => (
        <span>
          {`${
            record.facilitators && record.facilitators.length > 0
              ? record.facilitators
                  .map(
                    (facilitator) =>
                      `${facilitator.firstName} ${facilitator.lastName}`
                  )
                  .join(", ")
              : ""
          }${
            record.teamFacilitators && record.teamFacilitators.length > 0
              ? ", " +
                record.teamFacilitators
                  .map((facilitator) => facilitator.name)
                  .join(", ")
              : ""
          }`}
        </span>
      ),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      filteredValue: [creditSearchText],
      onFilter: (value, record) => {
        return value == undefined || value == "undefined" || value == ""
          ? true
          : record.participant?.credit == value;
      },
      render: (_, record) => (
        <>
          {record.participant?.credit ? "Yes" : "No"}
        </>
      ),
    },
    {
      title: "Notes",
      dataIndex: "note",
      key: "note",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => openNoteModal(record.participant?.note)}>
            View
          </Button>
        </Space>
      ),
    },
    {
      title: "Paid",
      dataIndex: "feePaid",
      key: "feePaid",
      filteredValue: [paidSearchText],
      onFilter: (value, record) => {
        return value == undefined || value == "undefined" || value == ""
          ? true
          : record.participant?.feePaid == value;
      },
      render: (_, record) => (
        <>
          {record.participant?.feePaid && record.participant?.feePaid == "1"
            ? "Yes"
            : "No"}
        </>
      ),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this course?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteCourse();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  //HANDLE COURSE LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedEvents(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Course",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
        <CustomHeader
          items={[
            { title: "Users", onClick: () => navigate("/userList") },
            {
              title: userData.firstName + " " + userData.lastName,
              onClick: () =>
                navigate("/profile/edit", { state: { userData: userData } }),
            },
            { title: "Classes & Events" },
          ]}
        />
      </Header>
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
              >
                <Space
                  size="small"
                  align="center"
                  style={{
                    display: "flex",
                  }}
                >
                  <Select
                    placeholder="Type"
                    className="userListSearchBox"
                    onChange={handleEventTypeSearchChange}
                    options={[
                      {
                        label: "Class",
                        value: "Class",
                      },
                      {
                        label: "Event",
                        value: "Event",
                      },
                    ]}
                    allowClear
                    style={{ width: "150px" }}
                    value={selectedEventTypeValues}
                  ></Select>
                  <Select
                    placeholder="Credit"
                    className="userListSearchBox"
                    onChange={handleCreditSearchChange}
                    options={[
                      {
                        label: "Yes",
                        value: "1",
                      },
                      {
                        label: "No",
                        value: "0",
                      },
                    ]}
                    allowClear
                    style={{ width: "150px" }}
                    value={selectedCreditValues}
                  ></Select>
                  <Select
                    placeholder="Paid"
                    className="userListSearchBox"
                    onChange={handlePaidSearchChange}
                    options={[
                      {
                        label: "Yes",
                        value: "1",
                      },
                      {
                        label: "No",
                        value: "0",
                      },
                    ]}
                    allowClear
                    style={{ width: "150px" }}
                    value={selectedPaidValues}
                  ></Select>
                  <Input.Search
                    placeholder="Course Code"
                    className="userListSearchBox"
                    onSearch={(value) => {
                      setCourseCodeSearchText(value);
                    }}
                    onChange={(e) => {
                      setCourseCodeSearchText(e.target.value);
                    }}
                    style={{ width: "200px" }}
                    value={courseCodeSearchText}
                  />
                </Space>
                <Space
                  size="small"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  {selectedEvents.length === 1 && (
                    <Button
                      onClick={showDeleteModal}
                      className="confirmButton"
                      style={{ marginBottom: "8px" }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    onClick={() => clearFilters()}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                  >
                    Clear
                  </Button>
                </Space>
              </Space>
              <Table
                rowClassName={"tableRowActiveCourse"}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={events}
                rowKey={(record) => record.id}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            </div>
          </Flex>
        </Flex>
      </Card>
      <NoteShow
        onOpenShowNoteModal={onOpenShowNoteModal}
        onCancelShowNoteModal={onCancelShowNoteModal}
        note={note}
      />
    </>
  );
}
export default ClassList;
