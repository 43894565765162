const config = require("../config");

class FacilitatorService {
  constructor(httpClient) {
    this.BASE_URL = config.BASE_URL;
    this.httpClient = httpClient;
  }

  async getAllFacilitators() {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetAllFacilitators?"
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch facilitators.");
      }

      const facilitators = await response.json();
      return facilitators;
    } catch (error) {
      console.error("Error fetching facilitators: ", error);
      return [];
    }
  }


  async createFacilitator(facilitator) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/CreateFacilitator",
        facilitator
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async batchUpdateFacilitators(facilitatorsActionModel) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/BatchFacilitatorUpdate",
        facilitatorsActionModel
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateFacilitator(facilitator) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/UpdateFacilitator",
        facilitator
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }

      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async deleteFacilitator(facilitator) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/DeleteFacilitatorById",
        facilitator
      );

      if (response.status === 401) {
        const error = new Error("Unauthorized");
        error.code = 401;
        throw error;
      }
      
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default FacilitatorService;
