import { AutoComplete, Button, Col, Form, Modal, Select, Radio, Row } from "antd";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { familyRoles } from "../../utils/constants";
import "react-phone-input-2/lib/style.css";
import { ExclamationCircleFilled } from "@ant-design/icons";

function AddFamilyMember({
  onOpenCreateFamilyMemberModal,
  onCancelCreateFamilyMemberModal,
  onCreateFamilyMember,
  onEditFamilyMember,
  familyMember,
  user
}) {
  const { instance } = useMsal();
  const [ form ] = Form.useForm();
  const { confirm } = Modal;
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [familyPlan, setFamilyPlan] = useState(null);
  const [users, setUsers] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState({});
  const [inputValue, setInputValue] = useState("");

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    fetchUsers();
  }, [onOpenCreateFamilyMemberModal]);
  useEffect(() => {
    let editMode = familyMember && Object.keys(familyMember).length > 0;
    setSelectedFamilyMember(familyMember);
    setIsEditMode(editMode);
    if (familyMember) {
      form.setFieldsValue(familyMember);
    }
  }, [familyMember]);

  async function fetchUsers() {
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const userService = new UserService(_fetchInstance);

    try {
      if (users.length > 0) {
        setUsers(
          originalUsers
          .filter((u) => u.id !== user.id && !user.familyMembers?.some((fm) => fm.id === u.id))
          .map((user) => {
            return {
              key: user.id,
              label: `${user.firstName} ${user.lastName}`,
              value: `${user.firstName} ${user.lastName}`,
            };
          })
        )
      } else {
        const usersData = await userService.getUsers();
        setOriginalUsers(usersData);
        setUsers(
          usersData
          .filter((u) => u.id !== user.id && !user.familyMembers?.some((fm) => fm.id === u.id))
          .map((user) => {
            return {
              key: user.id,
              label: `${user.firstName} ${user.lastName}`,
              value: `${user.firstName} ${user.lastName}`,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  }

  const getHouseholdAddress = (user) => {
    let address = "";
    if (!user ||  Object.keys(user).length < 1) {
      return address;
    }

    if (user.shippingAddress1) {
      address += user.shippingAddress1;
    }
    if (user.shippingAddress2) {
      address += ", " + user.shippingAddress2;
    }
    if (user.shippingState) {
      address += ", " + user.shippingState;
    }

    return address;
  }

  const getFullName = (user) => {
    if (!user || Object.keys(user).length === 0) {
      return "";
    }
    else if (!user.firstName && !user.lastName) {
      return user.name;
    }
    return `${user.firstName} ${user.lastName}`;
  }

  const onFamilyMemberSearch = (query) => {
    const filterToken = query.toLowerCase();
		if (typeof query !== 'string' || query.length < 3) {
			setSuggestedUsers([]);
			return;
		}
		const suggestions = users?.filter(user => user.label.toLowerCase().includes(filterToken));
    setSuggestedUsers(suggestions);
  };

  const onFamilyMemberSelect = (value, option) => {
    let selectedUser = originalUsers.find(user => user.id === option.key);
    setSelectedFamilyMember(selectedUser);
    setInputValue(value);
    isButtonDisabled();
  };

  const onSelectChange = () => {
    isButtonDisabled();
  };

  const familyMemberIsSelected = () => {
    return selectedFamilyMember && Object.keys(selectedFamilyMember).length > 0;
  }

  const isButtonDisabled = () => {
    const familiMemberValues = form.getFieldsValue();
    if (!isEditMode) {
      if (!familiMemberValues.user || !familiMemberValues.familyMemberIs || !familiMemberValues.currentUserIs || (!familiMemberValues.familyPlan && addToFamilyPlanIsRequired()) )
        return setIsSaveDisabled(true);
      else
        return setIsSaveDisabled(false);
    }
    else if (isEditMode && (!familiMemberValues.familyMemberIs || !familiMemberValues.currentUserIs || (!familiMemberValues.familyPlan && addToFamilyPlanIsRequired())))
      return setIsSaveDisabled(true);
    else 
      return setIsSaveDisabled(false);
  }

  const addFamilyMember = () => {
    let familyMemberObj = {
      ...form.getFieldsValue(),
      familyMemberId: selectedFamilyMember.id,
    }
    onCreateFamilyMember(familyMemberObj)
  }

  const editFamilyMember = () => {
    let familyMemberObj2 = {
      ...familyMember,
      ...form.getFieldsValue(),
    }
    onEditFamilyMember(familyMemberObj2)
  }

  const showUpdateModal = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you would like to update this address?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        updateAddress();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const updateAddress = async () => {
    setIsSaveDisabled(true);
    const updatedUser = {
      email: selectedFamilyMember.email,
      shippingAddress1: user.shippingAddress1 ?? "",
      shippingAddress2: user.shippingAddress2 ?? "",
      shippingCity: user.shippingCity ?? "",
      shippingState: user.shippingState ?? "",
      shippingZipCode: user.shippingZipCode ?? "",
      shippingCountry: user.shippingCountry ?? "",
    };
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _userService = new UserService(_fetchClient);

    try {
      await _userService.updateUser(updatedUser);
      setSelectedFamilyMember({
        ...selectedFamilyMember,
        ...updatedUser
      })
      isButtonDisabled();
    } catch (error) {
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      } 
      else {
        isButtonDisabled();
      }
    }
  }

  const addToFamilyPlanIsRequired = () => {
    if (user.dues && user.dues?.length > 0) {
      const currentDate = new Date();
      for (const due of user.dues) {
        const expiryDate = new Date(due.expiryDate);
        if ((due.type === 'Family 2 Year' || due.type === 'Family') && expiryDate >= currentDate)
          return true;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Family Member" : "Add Family Member"}
        centered
        destroyOnClose={true}
        open={onOpenCreateFamilyMemberModal}
        onCancel={() => {
          setFamilyPlan(null);
          form.resetFields();
          setIsSaveDisabled(true);
          onCancelCreateFamilyMemberModal();
        }}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => {
              setFamilyPlan(null);
              setInputValue("");
              setSelectedFamilyMember({});
              setSuggestedUsers([]);
              form.resetFields();
              setIsSaveDisabled(true);
              onCancelCreateFamilyMemberModal();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            htmlType="submit"
            className="confirmButton"
            disabled={isSaveDisabled}
            onClick={
              isEditMode
                ? () => editFamilyMember()
                : () => addFamilyMember()
            }
          >
            {isEditMode ? "Save" : "Add +"}
          </Button>,
        ]}
      >
        { !isEditMode && (
          <Row>
            <Col span={24}>
              <span style={{ fontWeight: 'bold' }}>Household Address: </span>
              <span>{getHouseholdAddress(user)}</span>
            </Col>
          </Row>
        )}
        <Form form={form} layout="vertical">
          { !isEditMode && (
            <Form.Item 
              name="user" 
              label="Search and select family member to add:" 
              rules={[{ required: !isEditMode}]}
            >
              <AutoComplete
                allowClear
                name="user"
                placeholder="Search"
                value={inputValue}
                options={suggestedUsers}
                onSearch={onFamilyMemberSearch}
                onSelect={onFamilyMemberSelect}
                onBlur={() => {
                  setSuggestedUsers([]);
                  form.setFieldsValue({
                    user: inputValue
                  });
                }}
                onClear={() => {
                  setInputValue("");
                  setSelectedFamilyMember({});
                  setSuggestedUsers([]);
                  form.setFieldsValue({
                    user: ""
                  });
                }}
              >
              </AutoComplete>
            </Form.Item>
          )}
          <Row>
            <Col span={24}>
              <span style={{ fontWeight: 'bold' }}>Name: </span>
              <span>{getFullName(selectedFamilyMember)}</span>
            </Col>
          </Row>
          { !isEditMode && (
            <>
              <Row >
                <Col span={16}>
                  <span style={{ fontWeight: 'bold' }}>Address: </span>
                  <span style={{ }}>{getHouseholdAddress(selectedFamilyMember)}</span>
                </Col>
                <Col span={8}>
                  {familyMemberIsSelected() && (
                    <div style={{ textAlign: "right" }}>
                      <Button
                        className="confirmButton"
                        onClick={showUpdateModal}
                      >
                        Update Address
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>Family status: </span>
                  <span>{selectedFamilyMember?.familyStatus ?? ""}</span>
                </Col>
              </Row>
            </>
          )}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item 
                name="familyMemberIs"
                label="Family Member is:"
                rules={[{ required: !isEditMode }]}
              >
                <Select
                  name="familyMemberIs"
                  showSearch
                  optionFilterProp="label"
                  placeholder="Family Role"
                  onChange={onSelectChange}
                  options={familyRoles}
                  disabled={!familyMemberIsSelected()}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                name="currentUserIs" 
                label="Current User is:" 
                rules={[{ required: !isEditMode }]}
              >
                <Select
                  name="currentUserIs"
                  showSearch
                  optionFilterProp="label"
                  placeholder="Family Role"
                  onChange={onSelectChange}
                  options={familyRoles}
                  disabled={!familyMemberIsSelected()}
                />
              </Form.Item>
            </Col>
          </Row>
            <Form.Item 
              name="familyPlan" 
              label="Add to Family Plan" 
              rules={[{ required: !isEditMode || !addToFamilyPlanIsRequired() }]}
            >
              <Radio.Group
                onChange={(v) => {
                  setFamilyPlan(v.target.value);
                  isButtonDisabled();
                }}
                value={familyPlan}
                disabled={!familyMemberIsSelected() || !addToFamilyPlanIsRequired()}
              >
                <Radio value={"1"}>Yes</Radio>
                <Radio value={"0"}>No</Radio>
              </Radio.Group>
            </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default AddFamilyMember;
