import { Button, Form, Input, Modal, Select, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
const { TextArea } = Input;

function NoteShow({
  onOpenShowNoteModal,
  onCancelShowNoteModal,
  note,
}) {
  const [form] = Form.useForm();

  const cancelModal = () => {
    onCancelShowNoteModal();
  };

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={"Note"}
        centered
        destroyOnClose={true}
        open={onOpenShowNoteModal}
        onOk={() => cancelModal()}
        onCancel={() => cancelModal()}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => cancelModal()}
          >
            Close
          </Button>
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item>
            <TextArea
              style={{ height: "80px" }}
              name="note"
              placeholder="Note"
              readOnly
              value={note ? note : ""}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default NoteShow;
