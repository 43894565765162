import { AutoComplete, Button, Form, Input, Modal, Select, DatePicker, Row, Col } from "antd";
import UserService from "../../../services/UserService";
import { ModalFooter } from "../../../components/ModalFooter";
import FetchClient from "../../../serviceClients/FetchClient";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import "react-phone-input-2/lib/style.css";

const { TextArea } = Input;

function CreateTeamMember({
  onOpenCreateTeamMemberModal,
  onCancelCreateTeamMemberModal,
  onCreateTeamMember,
  onEditTeamMember,
  teamMember,
  teamMembers
}) {
  const { instance } = useMsal();
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [users, setUsers] = useState([]);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState({});
  const [inputValue, setInputValue] = useState("");

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setIsEditMode(teamMember && Object.keys(teamMember).length > 0);
    if (teamMember && Object.keys(teamMember).length > 0) {
      setSelectedTeamMember({ id: teamMember.id, originalId: teamMember.id });
      setInputValue(teamMember.name);
      form.setFieldsValue({...teamMember, user: teamMember.name});
    }
  }, [teamMember]);

  async function fetchUsers() {
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE USER SERVICE INSTANCE
    const userService = new UserService(_fetchInstance);

    try {
      const usersData = await userService.getUserMembers();
      setUsers(
        usersData
        .filter((u) => !teamMembers.some((tm) => tm.id === u.id))
        .map((user) => {
          return {
            key: user.id,
            label: `${user.firstName} ${user.lastName}`,
            value: `${user.firstName} ${user.lastName}`,
          };
        })
      );
    } catch (error) {
      console.log(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    }
  }

  const onTeamMemberSearch = (query) => {
    const filterToken = query.toLowerCase();
		if (typeof query !== 'string' || query.length < 3) {
			setSuggestedUsers([]);
			return;
		}
		const suggestions = users?.filter(user => user.label.toLowerCase().includes(filterToken));
    setSuggestedUsers(suggestions);
  };

  const onTeamMemberSelect = (value, option) => {
    setSelectedTeamMember({
      ...selectedTeamMember,
      id: option.key,
    })
    setInputValue(option.value);
  };

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Team Member" : "Add Team Member"}
        centered
        destroyOnClose={true}
        open={onOpenCreateTeamMemberModal}
        onCancel={() => {
          form.resetFields();
          onCancelCreateTeamMemberModal();
        }}
        footer={[]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="user" label="Name" rules={[
              {
                required: true,
              },
            ]}>
              <AutoComplete
                allowClear
                name="user"
                placeholder="Search"
                value={inputValue}
                options={suggestedUsers}
                onSearch={onTeamMemberSearch}
                onSelect={onTeamMemberSelect}
                onBlur={() => {
                  setSuggestedUsers([]);
                  form.setFieldsValue({
                    user: inputValue
                  });
                }}
                onClear={() => {
                  setInputValue("");
                  setSuggestedUsers([]);
                  form.setFieldsValue({
                    user: ""
                  });
                }}
              />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="startDate" label="Start Date" rules={[
              {
                required: true,
              },
            ]}>
                <DatePicker
                  name="startDate"
                  className="userListSearchBox"
                  style={{ width: "100%" }}
                  // onChange={onDateInputChange}
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="endDate" label="End Date">
                <DatePicker
                  name="endDate"
                  className="userListSearchBox"
                  style={{ width: "100%" }}
                  // onChange={onDateInputChange}
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="note" label="Note">
            <TextArea
              style={{ height: "80px" }}
              name="note"
              placeholder=""
              // onChange={onInputChange}
            />
          </Form.Item>
          <ModalFooter
            form={form}
            onCancel={() => {
              form.resetFields();
              onCancelCreateTeamMemberModal();
            }}
            onConfirm={
              isEditMode
                ? () =>
                    onEditTeamMember({
                      ...selectedTeamMember,
                      ...form.getFieldsValue(),
                    })
                : () => onCreateTeamMember({...selectedTeamMember, ...form.getFieldsValue()})
            }
            submitText={isEditMode ? "Save" : "Add Team member"}
          />
        </Form>
      </Modal>
    </div>
  );
}

export default CreateTeamMember;
