import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useLocation } from "react-router-dom";
import { InfoCircle } from "react-bootstrap-icons";
import {
  Button,
  Card,
  Col,
  Select,
  Form,
  Input,
  Row,
  Spin,
  message,
  DatePicker,
  Tooltip
} from "antd";
import { Country, State } from "country-state-list";
import EventService from "../../../services/EventService";
import CourseService from "../../../services/CourseService";
import FetchClient from "../../../serviceClients/FetchClient";
const { TextArea } = Input;

export const SubmitButton = ({ form, children }) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Button htmlType="submit" className="confirmButton" disabled={!submittable}>
      {children}
    </Button>
  );
};

function EventInformation({ setActiveTab, eventData, setEventData, isSaveDisabled, setIsSaveDisabled, setTabsDisabledDisabled }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [shippingStateDisabled, setShippingStateDisabled] = useState(true);
  const [isPrerecordedSelected, setIsPrerecordedSelected] = useState(false);
  const [statesList, setStatesList] = useState(true);
  const [error, setError] = useState({});
  const [prerequisitesCourses, setPrerequisitesCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [originalCourses, setOriginalCourses] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [countries, setCountries] = useState([]);
  const [isClassSelected, setIsClassSelected] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const eventTypeOptions = [
    { id: 1, label: "Class", value: "Class" },
    { id: 2, label: "Event", value: "Event" },
  ];
  const classTypeOptions = [
    { id: 1, label: "Pre-recorded DVD", value: "Pre-recorded DVD" },
    { id: 2, label: "Pre-recorded On Demand", value: "Pre-recorded On Demand" },
    { id: 3, label: "Live Streamed", value: "live" },
    { id: 4, label: "In Person", value: "person" },
    { id: 5, label: "Hybrid", value: "hybrid" },
  ];
  const selectedEvent = location.state?.event;

  useEffect(() => {
    if (eventData && Object.keys(eventData).length > 0) {
      form.setFieldsValue({
        ...eventData,
        eventStartDate: eventData.eventStartDate ? dayjs(eventData.eventStartDate).utc() : "",
      });
      if (eventData.classType === "Pre-recorded On Demand" || eventData.classType === "Pre-recorded DVD")
        setIsPrerecordedSelected(true);
      else
        setIsPrerecordedSelected(false);

      if (eventData.eventType == "Class")
        setIsClassSelected(true);

      if (eventData.country == "usa" || eventData.country == "can") {
        setShippingStateDisabled(false);
        onCountryChange(eventData.country);
      }
    }
  }, [eventData]);

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const setButtonDisabled = () => {
    const eventValues = form.getFieldsValue();
    if (!eventValues.eventType || !eventValues.CTIId || !eventValues.eventName)
      setIsSaveDisabled(true);
    else if (eventValues.eventType === "Class" && (!eventValues.classType || !eventValues.courseId))
      setIsSaveDisabled(true);
    else if (eventValues.classType !== "Pre-recorded On Demand" && eventValues.classType !== "Pre-recorded DVD" && 
            (!eventValues.eventStartDate  || !eventValues.address1 || !eventValues.city || !eventValues.country))
      setIsSaveDisabled(true);
    else if (eventValues.classType !== "Pre-recorded On Demand" && eventValues.classType !== "Pre-recorded DVD" && 
            (!eventValues.zipCode || !eventValues.state) && 
            (eventValues.country === "usa" || eventValues.country === "can"))
      setIsSaveDisabled(true);
    else
      setIsSaveDisabled(false);
  };

  const handleFormChange = () => {
    setButtonDisabled();
  };

  const handleClassTypeForm = (record) => {
    if (record == "Pre-recorded DVD" || record == "Pre-recorded On Demand") {
      setIsPrerecordedSelected(true);
      form.setFieldsValue({
        eventStartDate: null,
        address1: null,
        address2: null,
        country: null,
        state: null,
        city: null,
        zipCode: null
      });
    } else {
      setIsPrerecordedSelected(false);
    }
    setButtonDisabled();
  };

  const handleCourseChange = (e) => {
    setButtonDisabled();
    const selectedCourse = originalCourses.find((course) => course.id == e);
    if (
      selectedCourse &&
      selectedCourse.prerequisites &&
      selectedCourse.prerequisites.length > 0
    ) {
      setPrerequisitesCourses(
        originalCourses
          .filter((course) => selectedCourse.prerequisites.includes(course.id))
          .map((course) => {
            return {
              label: course.name,
              value: course.id,
            };
          })
      );
    } else {
      setPrerequisitesCourses([]);
    }
  };

  function onCountryChange(value) {
    if (value && value.toLowerCase() === "usa") {
      setShippingStateDisabled(false);
      let states = State.getStatesOfCountry("US");
      setStatesList(
        states.map((state) => {
          return {
            label: state.name + " (" + state.isoCode + ")",
            value: state.name,
          };
        })
      );
    } else if (value && value.toLowerCase() === "can") {
      setShippingStateDisabled(false);
      let states = State.getStatesOfCountry("CA");
      setStatesList(
        states.map((state) => {
          return {
            label: state.name + " (" + state.isoCode + ")",
            value: state.name,
          };
        })
      );
    } else {
      setShippingStateDisabled(true);
      setStatesList([]);
      form.setFieldsValue({
        state: "",
        zipCode: "",
      });
    }
    setButtonDisabled();
  }

  const onEventTypeChange = (value) => {
    setButtonDisabled();
    if (value === "Class") {
      setIsClassSelected(true);
    } else {
      setIsClassSelected(false);
      setIsPrerecordedSelected(false);
      setPrerequisitesCourses([]);
      form.setFieldsValue({
        classType: "",
        courseId: "",
      });
    }
    setSelectedType(value);
  };
  const onDateInputChange = () => {
    setButtonDisabled();
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    // Call Service to update event data
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _eventService = new EventService(_fetchClient);

    try {
      let res, newEventData;
      let eventId = localStorage.getItem("eventId");
      const eventObject = {
        eventType: values.eventType,
        classType: values.classType || null,
        courseId: values.courseId ?? null,
        CTIId: values.CTIId,
        eventStartDate: isPrerecordedSelected ? null : dayjs(values.eventStartDate).toISOString() ?? null,
        eventName: values.eventName,
        description: values.description ?? null,
        country: isPrerecordedSelected ? null : values.country,
        address1: isPrerecordedSelected ? null : values.address1,
        address2: isPrerecordedSelected ? null : values.address2 ?? null,
        city: isPrerecordedSelected ? null : values.city ?? null,
        state: isPrerecordedSelected ? null : values.state ?? null,
        zipCode: isPrerecordedSelected ? null : values.zipCode ?? null,
      };
      if (eventId) {
        eventObject.id = eventId;
        if (isPrerecordedSelected) {
          eventObject.status = "Draft";
        }
        res = await _eventService.updateEvent(eventObject);
        messageApi.open({
          type: "success",
          content: "Event updated successfully!",
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });
        newEventData = { 
          ...res,
          schedules: eventData.schedules,
          facilitators: eventData.facilitators,
          teamFacilitators: eventData.teamFacilitators 
        };
      } else {
        if (isPrerecordedSelected) {
          eventObject.status = "Draft";
        }
        res = await _eventService.createEvent(eventObject);
        messageApi.open({
          type: "success",
          content: "Event created successfully!",
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });
        newEventData = res;
      }
      setEventData(newEventData);
      setTabsDisabledDisabled(false);
      localStorage.setItem("eventId", res.id);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsSaveDisabled(true);
      setIsLoading(false);
      setActiveTab(2);
    }
  };

  //GET COURSE LIST
  async function fetchCourses() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const courseService = new CourseService(_fetchInstance);

    try {
      const courseData = await courseService.getAllCourses();
      setOriginalCourses(courseData);

      setCourses(
        courseData.map((course) => {
          return {
            label: course.name,
            value: course.id,
          };
        })
      );

      if (eventData && Object.keys(eventData).length > 0) {
        const selectedCourse = courseData.find((course) => course.id == eventData.courseId);
        if (
          selectedCourse &&
          selectedCourse.prerequisites &&
          selectedCourse.prerequisites.length > 0
        ) {
          setPrerequisitesCourses(
            courseData
              .filter((course) => selectedCourse.prerequisites.includes(course.id))
              .map((course) => {
                return {
                  label: course.name,
                  value: course.id,
                };
              })
          );
        } else {
          setPrerequisitesCourses([]);
          if (eventData.courseId) {
            form.setFieldsValue({
              courseId: null,
            });
          }
        }
      }
    } catch (error) {
      setError(error);
      if (error.code === 401) {
        localStorage.removeItem("loggedUser");
        await instance.logout({
          onRedirectNavigate: () => {
            return false;
          }
        });
        await instance.loginRedirect();
      }
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchCourses();
    if (countries != []) {
      setCountries(
        Country.getAllCountries().map((country) => {
          return country.isoCode == "US"
            ? {
                label: country.name,
                value: "usa",
              }
            : country.isoCode == "CA"
            ? {
                label: country.name,
                value: "can",
              }
            : {
                label: country.name,
                value: country.name,
              };
        })
      );
    }
  }, []);

  const [form] = Form.useForm();

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="accountDetailsForm"
        onFinish={onFinish}
      >
        <span style={{ fontSize: 16, fontWeight: "bold" }}>Event details</span>
        <Row style={{marginTop: "10px"}} gutter={24}>
          <Col span={6}>
            <Form.Item
              name="eventType"
              label="Type"
              rules={[{ required: true, message: "Type is required" }]}
            >
              <Select
                name="eventType"
                className="userListSearchBox"
                onChange={onEventTypeChange}
                options={eventTypeOptions}
                allowClear
                value={selectedType}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            {isClassSelected && (
              <Form.Item
                name="classType"
                label="Class Type"
                rules={[
                  {
                    required: isClassSelected,
                    message: "Class type is required",
                  },
                ]}
              >
                <Select
                  name="classType"
                  className="userListSearchBox"
                  onChange={handleClassTypeForm}
                  options={classTypeOptions}
                  allowClear
                ></Select>
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {isClassSelected && (
              <Form.Item
                name="courseId"
                label="Course"
                rules={[
                  { required: isClassSelected, message: "Course is required" },
                ]}
              >
                <Select
                  name="courseId"
                  className="userListSearchBox"
                  onChange={handleCourseChange}
                  options={courses}
                  placeholder={eventData.courseId ? "Course Not Found" : "Select a course"}
                  allowClear
                ></Select>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Event code is required",
                      max: 28,
                      message: "Please insert up to 28 characters",
                    },
                  ]}
                  name="CTIId"
                  label={
                    <div>
                      <span style={{ marginRight: "5px" }}>CTI ID</span>
                      <Tooltip placement="rightTop" overlayStyle={{minWidth: '500px'}} title={
                        <div>
                          <div style={{ padding: "10px" }}>
                            <h3>CTI ID Format</h3>
                            <h4><strong>Format</strong>: EE-CC-YYMMDD-FF-RND#</h4>
                            <div style={{margin: '8px'}}>
                              <div><strong>EE = Event Type</strong></div>
                              <div>If Event Type is a Class, set EE to CL</div>
                              <div>If Event Type is an Event, set EE to EV</div>
                            </div>
                            <div style={{margin: '8px'}}>
                              <div><strong>CC = Code (Code assigned to Course or Event)</strong></div>
                              <div>Example: If Course is "Conceptology Phase 1" set CC to PH1</div>
                            </div>
                            <div style={{margin: '8px'}}>
                              <div><strong>YY = Year. Example 24 for 2024</strong></div>
                              <div><strong>MM = Month (01-12), Example 06 for June</strong></div>
                              <div><strong>DD = Day (01-31)</strong></div>
                            </div>
                            <div style={{margin: '8px'}}>
                              <div><strong>FF = Class Format Type</strong></div>
                              <div>The following are the codes for the different types of format for classes:</div>
                            </div>
                            <div style={{margin: '8px'}}>
                              <div>PRD : Pre-recorded DVD</div>
                              <div>PROD: Pre-recorded On Demand</div>
                              <div>IP: In Person Class</div>
                              <div>LS: Live Streamed</div>
                              <div>HY: Hybrid</div>
                            </div>
                            <div style={{margin: '8px'}}>
                              <div><strong>RND# : Auto-generated 3 character random number (e.g., "000")</strong></div>
                            </div>
                          </div>
                        </div>}>
                        <InfoCircle />
                      </Tooltip>
                    </div>
                  }
                >
                  <Input onChange={handleFormChange} defaultValue={""} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="eventStartDate"
                  label="Start Date"
                  rules={[
                    { 
                      required: !isPrerecordedSelected,
                      message: "Start date is required" 
                    },
                  ]}
                >
                  <DatePicker
                    name="eventStartDate"
                    className="userListSearchBox"
                    style={{ width: "100%" }}
                    onChange={onDateInputChange}
                    disabled={isPrerecordedSelected}
                  ></DatePicker>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Class/Event Name"
                  name="eventName"
                  rules={[
                    { required: true, message: "Event name is required" },
                  ]}
                >
                  <Input onChange={handleFormChange} defaultValue={""} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="description" label="Description">
                  <TextArea
                    style={{ height: "180px" }}
                    name="description"
                    placeholder="Description"
                    onChange={handleFormChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            {isClassSelected &&
              prerequisitesCourses &&
              prerequisitesCourses.length > 0 && (
                <>
                  <strong>Prerequisites</strong>
                  {prerequisitesCourses.map((course) => {
                    return (
                      <>
                        <br></br>
                        <span>{course.label}</span>
                      </>
                    );
                  })}
                </>
              )}
          </Col>
        </Row>
        <span style={{ fontSize: 16, fontWeight: "bold" }}>Event location</span>
        <Row  style={{marginTop: "10px"}} gutter={24}>
          <Col span={12}>
            <Form.Item 
              name="country" 
              label="Country"
              rules={[{
                required: !isPrerecordedSelected,
                message: "This field is required"
              }]}
            >
              <Select
                name="country"
                showSearch
                optionFilterProp="label"
                className="userListSearchBox"
                onChange={(e) => {
                  onCountryChange(e);
                }}
                options={countries}
                allowClear
                disabled={isPrerecordedSelected}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Address 1"
              name="address1"
              rules={[{ 
                required: !isPrerecordedSelected, 
                message: "Address is required" 
              }]}
            >
              <Input 
                onChange={handleFormChange}
                defaultValue={""}
                disabled={isPrerecordedSelected}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Address 2"
              name="address2"
              rules={[{
                message: "Address 2 is required"
              }]}
            >
              <Input 
                onChange={handleFormChange} 
                defaultValue={""}
                disabled={isPrerecordedSelected}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[{
                required: !isPrerecordedSelected,
                message: "City is required"
              }]}
            >
              <Input 
                onChange={handleFormChange}
                defaultValue={""}
                disabled={isPrerecordedSelected}
              />
            </Form.Item>
          </Col>
          {!shippingStateDisabled && (
            <>
              <Col span={6}>
                <Form.Item 
                  label="State"
                  name="state"
                  rules={[{
                    required: !isPrerecordedSelected || !shippingStateDisabled,
                    message: "State is required"
                  }]}
                >
                  <Select
                    name="state"
                    className="userListSearchBox"
                    showSearch
                    optionFilterProp="label"
                    onChange={handleFormChange}
                    options={statesList}
                    allowClear
                    disabled={isPrerecordedSelected}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Zip Code"
                  name="zipCode"
                  rules={[{
                    required: !isPrerecordedSelected || !shippingStateDisabled,
                    message: "Zip code is required"
                  }]}
                >
                  <Input 
                    onChange={handleFormChange}
                    defaultValue={""}
                    disabled={isPrerecordedSelected}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                size="middle"
                htmlType="submit"
                className="confirmButton"
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default EventInformation;
