import { Button, Form, Input, Modal, Select, Space } from "antd";
import React, { useState, useEffect } from "react";
import { ModalFooter } from "../../ModalFooter";
import "react-phone-input-2/lib/style.css";
const { TextArea } = Input;

function CreateCourse({
  onOpenCreateCourseModal,
  onCancelCreateCourseModal,
  onCreateCourse,
  onEditCourse,
  onInputChange,
  setCourse,
  editCourse,
  course,
  allCourses,
}) {
  const [form] = Form.useForm();
  const [prerequisitesOptions, setPrerequisitesOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState([]);

  const handlePrerequisitesChange = (value) => {
    setSelectedPrerequisites(value);
    setCourse({ ...course, prerequisites: value });
  };

  const cancelModal = () => {
    setSelectedPrerequisites([]);
    setPrerequisitesOptions(
      allCourses.map((course) => {
        return {
          label: course.name,
          value: course.id,
        };
      })
    );
    onCancelCreateCourseModal();
  };

  useEffect(() => {
    if (allCourses.length > 0) {
      setPrerequisitesOptions(
        allCourses.map((course) => {
          return {
            label: course.name,
            value: course.id,
          };
        })
      );
    }
  }, [allCourses]);

  useEffect(() => {
    if (editCourse && Object.keys(editCourse).length > 0) {
      setIsEditMode(true);
      form.setFieldsValue(editCourse)
      setCourse(editCourse)
      setPrerequisitesOptions(
        allCourses
        .filter((course) => editCourse.id !== course.id)
        .map((course) => {
          return {
            label: course.name,
            value: course.id,
          };
        })
      );
      if (editCourse.prerequisites && editCourse.prerequisites.length > 0) {
        setSelectedPrerequisites(
          allCourses
          .filter((course) => editCourse.prerequisites.includes(course.id))
          .map((course) => {
            return {
              label: course.name,
              value: course.id,
            };
          })
        );
      }
    } else {
      setIsEditMode(false);
      form.resetFields()
    }
  }, [editCourse]);

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Course" : "Add Course"}
        centered
        destroyOnClose={true}
        open={onOpenCreateCourseModal}
        onOk={isEditMode ? onEditCourse : onCreateCourse}
        onCancel={() => cancelModal()}
        footer={[]}
      >
        <Form form={form} layout="vertical" name="validateOnly">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              name="name"
              placeholder="Name"
              onChange={onInputChange}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 12,
            }}
          >
            <Form.Item
              style={{ width: "100%" }}
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                name="code"
                placeholder="Code"
                onChange={onInputChange}
              />
            </Form.Item>
            <Form.Item
              style={{ width: "100%" }}
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                name="order"
                placeholder="Order"
                onChange={onInputChange}
              />
            </Form.Item>
          </div>
          <Form.Item label="Description" name="description">
            <TextArea
              style={{ height: "80px" }}
              name="description"
              placeholder="Description"
              onChange={onInputChange}
            />
          </Form.Item>
          <Form.Item label="Prerequisites" name="prerequisites">
            <Select
              mode="multiple"
              name="prerequisites"
              placeholder="Select Prerequisites"
              className="userListSearchBox"
              onChange={handlePrerequisitesChange}
              options={prerequisitesOptions}
              allowClear
              value={selectedPrerequisites}
            ></Select>
          </Form.Item>
          <ModalFooter 
            form={form} 
            onCancel={cancelModal} 
            onConfirm={isEditMode ? onEditCourse : onCreateCourse}
            submitText={isEditMode ? "Save" : "Add"} 
          />
        </Form>
      </Modal>
    </div>
  );
}

export default CreateCourse;
