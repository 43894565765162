export const familyRoles = [
  { label: "Aunt", value: "Aunt" },
  { label: "Brother", value: "Brother" },
  { label: "Cousin", value: "Cousin" },
  { label: "Daughter", value: "Daughter" },
  { label: "Daughter-in-law", value: "Daughter-in-law" },
  { label: "Father", value: "Father" },
  { label: "Father-in-law", value: "Father-in-law" },
  { label: "Fiance", value: "Fiance" },
  { label: "Granddaughter", value: "Granddaughter" },
  { label: "Grandfather", value: "Grandfather" },
  { label: "Grandmother", value: "Grandmother" },
  { label: "Grandson", value: "Grandson" },
  { label: "Mother", value: "Mother" },
  { label: "Mother-in-law", value: "Mother-in-law" },
  { label: "Nephew", value: "Nephew" },
  { label: "Niece", value: "Niece" },
  { label: "Sister", value: "Sister" },
  { label: "Son", value: "Son" },
  { label: "Son-in-law", value: "Son-in-law" },
  { label: "Spouse", value: "Spouse" },
  { label: "Uncle", value: "Uncle" },
];

export const participantRoles = [
  { label: "Assistant", value: "Assistant" },
  { label: "Attendee", value: "Attendee" },
  { label: "Event Master of Ceremonies", value: "Event Master of Ceremonies" },
  { label: "Facilitator", value: "Facilitator" },
  { label: "Guest", value: "Guest" },
  { label: "Lifetime Membership", value: "Lifetime Membership" },
  { label: "Mentored Teacher", value: "Mentored Teacher" },
  { label: "New Student", value: "New Student" },
  { label: "New to Movement Student", value: "New to Movement Student" },
  { label: "Online Attendee", value: "Online Attendee" },
  { label: "Organizer", value: "Organizer" },
  { label: "Review Student", value: "Review Student" },
  { label: "Speaker", value: "Speaker" },
  { label: "Sponsor", value: "Sponsor" },
  { label: "Staff", value: "Staff" },
  { label: "Teacher", value: "Teacher" },
  { label: "Youth Event Attendee", value: "Youth Event Attendee" },
];
