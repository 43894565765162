import { Flex } from "antd";
import React from "react";
import UserList from "./Admin/User/UserList";
import Courses from "../pages/Settings/Courses"
import Classes from "../pages/ClassesEvents/Classes"
import ProfileClasses from "../pages/Profile/ClassesAndEvents"
import ClubMembership from "../pages/Profile/ClubMembership"
import FacilitatorTeams from "../pages/ClassesEvents/FacilitatorTeams"
import AddTeam from "../pages/ClassesEvents/AddFacilitatorTeams/AddTeam"
import AddEvent from "../pages/ClassesEvents/AddEvent"
import CodeFormat from "../pages/ClassesEvents/CodeFormat"
import { Route, Routes } from "react-router-dom";
import ProfileTabs from "../pages/Profile/ProfileTabs";
import MembersList from "./FamilyMembers/MembersList";

function LandingComponent() {
  return (
    <div style={{ flex: 1 }}>
      <Flex vertical gap="2.3rem">
        <Routes>
          <Route path="/" element={<ProfileTabs />}></Route>
          <Route path="/myProfile" element={<ProfileTabs />}></Route>
          <Route path="/profile/edit" element={<ProfileTabs />}></Route>
          <Route path="/classesAndEvents" element={<ProfileClasses />}></Route>
          <Route path="/clubMembership" element={<ClubMembership />}></Route>
          <Route path="/userList" element={<UserList />}></Route>
          <Route path="/courses" element={<Courses />}></Route>
          <Route path="/classesAndEventsList" element={<Classes />}></Route>
          <Route path="/addEvent" element={<AddEvent />}></Route>
          <Route path="/facilitatorTeams" element={<FacilitatorTeams />}></Route>
          <Route path="/addFacilitatorTeam" element={<AddTeam />}></Route>
          <Route path="/codeFormat" element={<CodeFormat />}></Route>
        </Routes>
      </Flex>
    </div>
  );
}

export default LandingComponent;
